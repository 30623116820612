<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">User <span>Registration</span></h4>
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img
            class="v3-logo"
            :src="'/assets/img/logo.png'"
            alt="Logo"
          />

          <p class="v3-sub-title">One app, All your connections</p>
          <h2 class="mt-5">Almost there</h2>

          <v-row class="zero-flex" justify="center">
            <v-col cols="12" sm="12" md="8" lg="7">
              <div>
                <v-row justify="center">
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <p>Your Address</p>
                    <v-autocomplete
                      background-color="white"
                      class="h50-field"
                      placeholder="Start Typing"
                      outlined
                      v-model="user.address"
                      :items="post_code_array"
                      :search-input.sync="searchLocation"
                      @keyup="autofillData(searchLocation)"
                      @change="getFullAddress(user.address)"
                      :error-messages="postCodeErrors"
                      @input="$v.user.post_code.$touch()"
                      @blur="$v.user.post_code.$touch()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <p
                  v-if="!enterManually"
                  @click="addressManually()"
                  class="address-manually"
                >
                  Enter address manually
                </p>

                <v-row v-if="enterManually" justify="center">
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      dense
                      placeholder="Address Line 1"
                      outlined
                      required
                      v-model="manuallyAddress.address_line_1"
                      :error-messages="addressLine1Errors"
                      @input="$v.manuallyAddress.address_line_1.$touch()"
                      @blur="$v.manuallyAddress.address_line_1.$touch()"
                      class="h50-field"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      dense
                      placeholder="Address Line 2"
                      outlined
                      required
                      class="h50-field"
                      v-model="manuallyAddress.address_line_2"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      dense
                      placeholder="City"
                      outlined
                      required
                      class="h50-field"
                      v-model="manuallyAddress.city"
                      :error-messages="cityErrors"
                      @input="$v.manuallyAddress.city.$touch()"
                      @blur="$v.manuallyAddress.city.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-select
                      placeholder="United Kingdom"
                      :items="country_codes"
                      background-color="white"
                      required
                      dense
                      outlined
                      class="h50-field"
                      v-model="manuallyAddress.country"
                      :error-messages="countryErrors"
                      @input="$v.manuallyAddress.country.$touch()"
                      @blur="$v.manuallyAddress.country.$touch()"
                    ></v-select>
                  </v-col>
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      dense
                      placeholder="PostCode/ZipCode"
                      outlined
                      required
                      class="h50-field"
                      v-model="manuallyAddress.post_code"
                      :error-messages="manuallyPostCodeErrors"
                      @input="$v.manuallyAddress.post_code.$touch()"
                      @blur="$v.manuallyAddress.post_code.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <br />
                <div>
                  <v-btn
                    :loading="saveLoading"
                    class="primary-bg-line-btn mt-3"
                    outlined
                    block
                    large
                    @click="merchantAddress()"
                  >
                    Continues
                  </v-btn>
                </div>
              </div>
              <br />
              <br />
              <div class="merchant-step-area">
                <p>2/2</p>
                <img
                  :src="'/assets/v3_assets/custom_shapes/m_step_4.png'"
                  alt="Logo"
                />
              </div>

              <div @click="goBack()" class="auth-back-btn">
                <v-btn class="v3-div-clr" elevation="0" depressed flex>
                  <v-icon dark size="20px"> mdi-arrow-left</v-icon>
                  Back
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
  
  <script>
import { mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import axios from "axios";
import MerchantBannerWidget from "../../merchant/auth/MerchantBannerWidget.vue";
import Cookies from "js-cookie";
export default {
  props: ["userInfo"],

  components: {
    MerchantBannerWidget,
  },

  data: () => ({
    saveLoading: false,
    searchLocation: null,
    searchLoading: false,
    enterManually: false,
    arrayOfAddress: [],
    post_code_array: [],
    selectedAddress: null,
    user: {
      address: "",
      city: "",
      post_code: "",
      country: 1,
    },
    snackbar: {
      show: false,
      message: "",
      color: null,
    },

    manuallyAddress: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      country: "",
      post_code: "",
      latitude: "",
      longitude: "",
      address_type_id: "",
      house_flat_number: "",
      region_name: "",
    },
    user_id: "",
    country_codes: [
      {
        text: "United Kingdom",
        value: 1,
      },
      {
        text: "India",
        value: 2,
      },
    ],
  }),

  validations: {
    user: {
      post_code: { required },
      address: { required },
    },

    manuallyAddress: {
      address_line_1: {
        required: requiredIf(function (abc) {
          return this.enterManually;
        }),
      },

      city: {
        required: requiredIf(function (abc) {
          return this.enterManually;
        }),
      },

      country: {
        required: requiredIf(function (abc) {
          return this.enterManually;
        }),
      },

      post_code: {
        required: requiredIf(function (abc) {
          return this.enterManually;
        }),
      },
    },
  },

  computed: {
    ...mapState({
      codeAddress: (state) => state.user_login.codeAddress,
      countries: (state) => state.custom.countries,
    }),
    postCodeErrors() {
      const errors = [];
      if (!this.$v.user.address.$dirty) return errors;
      !this.$v.user.address.required && errors.push("Post code is required.");
      return errors;
    },

    addressLine1Errors() {
      const errors = [];
      if (!this.$v.manuallyAddress.address_line_1.$dirty) return errors;
      !this.$v.manuallyAddress.address_line_1.required &&
        errors.push("Address line 1 is required");
      return errors;
    },

    cityErrors() {
      const errors = [];
      if (!this.$v.manuallyAddress.city.$dirty) return errors;
      !this.$v.manuallyAddress.city.required && errors.push("City is required");
      return errors;
    },

    countryErrors() {
      const errors = [];
      if (!this.$v.manuallyAddress.country.$dirty) return errors;
      !this.$v.manuallyAddress.country.required &&
        errors.push("Country is required");
      return errors;
    },

    manuallyPostCodeErrors() {
      const errors = [];
      if (!this.$v.manuallyAddress.post_code.$dirty) return errors;
      !this.$v.manuallyAddress.post_code.required &&
        errors.push("Postcode is required");
      return errors;
    },

  },

  watch: {
    codeAddress(addresses) {
      console.log(addresses);
      if (addresses) {
        this.$printLog("=====address1====>", addresses);
        this.showAddField = true;
        this.user.address = addresses.address;
        this.user.city = addresses.city;
        // this.user.country = addresses.country;
      } else {
        this.snackbar.show = true;
        this.snackbar.color = "error";
        this.snackbar.message = "Address not found please try again";
      }
    },
  },
  created() {
    if (this.$route.params.userInfo) {
      var item = this.$route.params.userInfo;
   
    } else {
      this.$router.replace("/user/signup");
    }
    this.$store.dispatch("getCountries", {});
  },
  methods: {
    addressManually() {
      this.enterManually = true;
    },

    goBack() {
      this.$router.push({
        name: "userRegisterYourDetail",
        params: { userInfo: this.$route.params.userInfo },
      });
    },

    homeDetail() {
    
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.validate = false;
      } else {
       
        this.validate = this.user;
      }
    },

    async autofillData(searchLocation) {
      if (searchLocation <= 2) {
        this.$store.dispatch(
          "globalState/errorSnackBar",
          "Please fill valid pin code."
        );
        return;
      }
      const form = new FormData();
      form.append("selected_post_code", searchLocation);
      await this.$recaptchaLoaded();
      const GCtoken = await this.$recaptcha("login");
      form.append("GCtoken", GCtoken);
      this.searchLoading = true;

      axios.post("/api/v3/get_web_address", form).then((response) => {
        if (response.data.data && response.data.data.suggestions) {
          if (response.data.data.suggestions.length < 1) {
            this.$store.dispatch(
              "globalState/successSnackBar",
              "Address not found."
            );
          } else {
            this.post_code_array = response.data.data.suggestions.map(
              (item) => {
                return {
                  text: item.address,
                  value: item.id,
                };
              }
            );

            this.$store.dispatch(
              "globalState/successSnackBar",
              "Please select address from list"
            );
          }
        } else {
          this.$store.dispatch(
            "globalState/successSnackBar",
            "Address not found."
          );
        }
      });
    },


    async getFullAddress(address_id) {
      const form = new FormData();
      form.append("address_id", address_id);

      axios
        .post("/api/v3/selectedUserData", form)
        .then((response) => {
          var address = response.data.data;

          this.manuallyAddress.address_line_1 = address.line_1;
          this.manuallyAddress.address_line_2 =
            address.line_2 + ", " + address.line_3;
          this.manuallyAddress.city = address.town_or_city;
          this.manuallyAddress.latitude = address.latitude;
          this.manuallyAddress.longitude = address.longitude;
          this.manuallyAddress.address_type_id = address.address_type_id;
          this.manuallyAddress.house_flat_number = address.building_number;
          this.manuallyAddress.post_code = address.postcode;
          this.manuallyAddress.region_name = address.county;
          this.manuallyAddress.country = 1;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    errorSnackBar(msg) {
      this.snackbar.show = true;
      this.snackbar.color = "error";
      this.snackbar.message = msg;
    },

    async merchantAddress() {
      // if (this.$v.$invalid) {
      //   this.$v.$touch();

      //   this.snackbar = {
      //     message: "Please check all form fields",
      //     color: "red",
      //     show: true,
      //   };
      // } else {
        const form = new FormData();
        form.append("address_id", this.user.address);
        form.append("user_id", this.$route.params.userInfo.id);
        form.append("section", "address");

        if (this.enterManually) {
          form.append("address1", this.manuallyAddress.address_line_1);
          form.append("address2", this.manuallyAddress.address_line_2);
          form.append("city_name", this.manuallyAddress.city);
          form.append("region_name", this.manuallyAddress.region_name);
          form.append("country_name", this.manuallyAddress.country);
          form.append("zipcode", this.manuallyAddress.post_code);
          form.append("latitude", this.manuallyAddress.latitude);
          form.append("longitude", this.manuallyAddress.longitude);
          form.append("address_type_id", this.manuallyAddress.address_type_id);
          form.append(
            "house_flat_number",
            this.manuallyAddress.house_flat_number
          );
        }

        await this.$recaptchaLoaded();
        const GCtoken = await this.$recaptcha("login");
        form.append("GCtoken", GCtoken);
        this.saveLoading = true;
        axios
          .post("/api/v3/user_register", form)
          .then((response) => {
            this.saveLoading = false;
            this.$emit("login", response.data.data);
            this.loader = false;
            let token = "Bearer " + response.data.data.token;
            axios.defaults.headers.common["Authorization"] = token;
            var remember = "";
            var now = new Date();
            now.setTime(now.getTime() + 1 * 3600 * 1000);
            Cookies.set("access_token", token, { expires: remember ? now : 1 });
            this.$store.commit("USER_LOGIN_STATUS", true);
            this.$store.commit("SHOW_SNACKBAR", {
              success: true,
              message: "Login successfully.",
            });
            this.$router.push({ name: "userConfirmationPage" });
            return;
          })
          .catch((e) => {
            this.saveLoading = false;
          });
      }
    },
  // },
};

</script>
  
  <style scoped>
.v-stepper__header {
  box-shadow: none !important;
}
</style>
  