import Vue from "vue";
import Vuex from "vuex";
import set_cookies from "./modules/set_cookies";
import user from "./modules/user";
import auth from "./modules/auth";
import globalState from "./modules/globalState";
import admin_log_reader from "./modules/admin_log_reader";

import manage_merchant from "./modules/Merchant/manage_merchant";
import download_plugin from "./modules/download_plugin";
import merchant_websites_store from "./modules/Merchant/merchant_websites_store";
import merchant_login from "./modules/Merchant/merchant_login";
import merchant_create_token from "./modules/Merchant/merchant_create_token";
import custom from "./modules/custom";
import packages from "./modules/Packages/packages";
import user_login from "./modules/Users/user_login";
import user_module from "./modules/Users/user_module";
import our_partners_module from "./modules/our_partners_module";
import Settings from "./modules/Settings";
import contact_us from "./modules/contact_us";
import UserDashboard from "./modules/Users/UserDashboard"; //
import home_page_section from "./modules/HomeSection/home_page_section"; //
import pre_register_user from "./modules/PreRegisterUser/pre_register_user"; //
import BannerSection from "./modules/BannerSection/BannerSection"; //
import api_logs_store from "./modules/api_logs_store" ;
import analytic from "./modules/Analytics/analytic" ;
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
Vue.use(Vuex);

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
          // if(key =='client') return;
          if(key =='showSnackBar' || key == 'loaderstate') {
            return;
        }
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

export const store = new Vuex.Store({
    plugins: [
        createPersistedState({
            getState: (function(key) {
                try {
                    
                    return JSON.parse(localStorage.getItem(key));
                    } catch (err) {

                       
                        // localStorage.removeItem('en_user');
                        // localStorage.removeItem('vuex');
                        // window.location = "";
                        // var cookies = document.cookie.split(";");
                        // for (var i = 0; i < cookies.length; i++)
                        // {
                        //     var spcook =  cookies[i].split("=");

                        //     var d = new Date();
                        //     d.setDate(d.getDate() - 1);
                        //     var expires = ";expires="+d;
                        //     var name=spcook[0];
                        //     //alert(name);
                        //     var value="";
                        //     document.cookie = name + "=" + value + expires + "; path=/acc/html";

                        // }

                        // window.location = ""; // TO REFRESH THE PAGE
                        // this.route.replace({ name: "login" });

                }
            }),
            setState: (key, state) => localStorage.setItem(key, JSON.stringify(state , getCircularReplacer()))
        })
    ],
    modules: {
        user,
        auth,
        set_cookies,
        manage_merchant,
        merchant_login,
        merchant_create_token,
        merchant_websites_store,
        custom,
        packages,
        user_login,
        user_module,
        our_partners_module,
        UserDashboard,
        home_page_section,
        pre_register_user,
        BannerSection,
        Settings,
        contact_us,
        api_logs_store,
        download_plugin,
        analytic,
        admin_log_reader ,
        globalState
    },
});