import '@mdi/font/css/materialdesignicons.css' 
import Vue from "vue";
import Vuetify from "vuetify/lib";
// import "vuetify/dist/vuetify.min.css";
 

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
        // iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        themes: {
            light: {
                primary: "#8653EB",
                primaryLight: "#CEB7FF",
                primaryLightest: "#EDE1FF",
                primaryLightShade500: "#D6B7FF",
                darkShade500: "#625A83",
                lightShade200: "#F1EBE6",
                bgLight: "#FCF9FF",
                dark: "#2D3446",
            },
        },
    },
};

export default new Vuetify(opts);
