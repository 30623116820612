import axios from "axios";

const state = {
  loaderstate: false,
  status: false,
  tokensData: [],
  showSnackBar: {
    show: false,
    message: "",
    color: "",
  },
};

const getters = {};

const actions = {
  get_Tokens(context, data) {
    context.commit("SHOW_LOADER", true);
     
    var api_url = "/api/merchant/get_tokens";
    axios
      .get(api_url)
      .then((response) => {
        var success_statuses = [200, 201, 202, 203, 204, 205];
        if (success_statuses.includes(response.data.status)) {
          context.commit("TOKENS_DATA", response.data.data);
        } else {
        }
        context.commit("SHOW_LOADER", false);
      })
      .catch((error) => {
        context.commit("SHOW_LOADER", false);
      });
  },
  create_testToken(context, data) {
    context.commit("SHOW_LOADER", true);
   

    var api_url = "/api/merchant/create_testToken";
    const form = new FormData();
    form.append("token_name", "merchant");
    form.append("token_type", data.token_type);
    // form.append("merchant_id", merchant_id);

    axios
      .post(api_url, form)
      .then((response) => {
        var success_statuses = [200, 201, 202, 203, 204, 205];
        if (success_statuses.includes(response.data.status)) {

          context.commit("TOKENS_DATA", response.data.data);
          
          context.commit("SHOW_SNACKBAR", {
            success: true,
            message: "Create Successfully",
          });
        } else {
          context.commit("SHOW_SNACKBAR", {
            success: false,
            message: "Create Failed!",
          });
        }
        context.commit("SHOW_LOADER", false);
      })
      .catch((error) => {
        context.commit("SHOW_LOADER", false);
        context.commit("SHOW_SNACKBAR", {
            success: false,
            message: "Create Failed!",
          });
      });
  },
};

const mutations = {
  //
  TOKENS_DATA(state, data) {
    state.tokensData = data;
  },
  SHOW_LOADER(state, status) {
    state.loaderstate = status;
  },

  SHOW_SNACKBAR(state, payload) {
    state.showSnackBar.show = true;
    state.showSnackBar.message = payload.message;
    var color = "red";
    if (payload.success) {
      color = "primary";
    }
    state.showSnackBar.color = color;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
