import axios from "axios";
import moment from "moment";

const state = {
    showSnackBar: {
        show: false,
        message: "",
        color: ""
    },
    loaderState: false,
    tabs: {
        generalTabs: 0,
        companyTabs: 0,
        employeeTabs: 0,
        logsTabs: 0,
        faqTabs: 0
    },

    adminSearchRoutes: [
        { text: "Dashboard",      value: "dashboard" ,       'action': "",         "subject" : "" },
        { text: "Gallery",        value: "gallery" ,         'action': "",         "subject" : "" },
        { text: "Charges",        value: "charges" ,         'action': "",         "subject" : "" },
        { text: "Settings",       value: "general"      ,    'action': "" ,        "subject" : "" },
        { text: "Plans",          value: "addPlans" ,        'action': "create",   "subject" : "plan" },
        { text: "Coupons",        value: "coupon" ,          'action': "create",   "subject" : "coupon" },
        { text: "Companies",      value: "companyList",      'action': "view",     "subject" : "company" },
        { text: "Add Company",    value: "addCompany",       'action': "create",   "subject" : "company" },
        { text: "Employee",       value: "employee" ,        'action': "view",     "subject" : "HRM" },
        { text: "Add Employee",   value: "addEmployee",      'action': "create",   "subject" : "HRM" },
        { text: "Lookup Table",   value: "lookupTable",      'action': "view",     "subject" : "lookup" },
        { text: "Brands",         value: "brand" ,           'action': "view",     "subject" : "lookup" },
        { text: "Category",       value: "category" ,        'action': "view",     "subject" : "lookup"},
        { text: "City",           value: "city" ,            'action': "view",     "subject" : "lookup"},
        { text: "State",          value: "state" ,           'action': "view",     "subject" : "lookup"},
        { text: "Country",        value: "country" ,         'action': "view",     "subject" : "lookup"},
        { text: "Currency",       value: "currency" ,        'action': "view",     "subject" : "lookup"},
        { text: "Customer Group", value: "customerGroup",    'action': "view",     "subject" : "lookup"},
        { text: "Tax",            value: "tax" ,             'action': "view",     "subject" : "lookup"},
        { text: "Unit",           value: "unit" ,            'action': "view",     "subject" : "lookup"},
        { text: "Warehouse",      value: "warehouse" ,       'action': "view",     "subject" : "lookup"},
        { text: "Department",     value: "department" ,      'action': "view",     "subject" : "lookup"},
        { text: "Manage Roles",   value: "manageRoles",      'action': "view" ,    "subject" : "role" },
        { text: "Mail Utilities", value: "mailUtility",      'action': "view" ,    "subject" : "mailUtility" },
        { text: "Logs",           value: "logApp",           'action': "view" ,    "subject" : "logs" },
        { text: "Support Tickets",value: "supportTickets",   'action': "view" ,    "subject" : "ticket" },
        { text: "Subscriptions",  value: "subscriptionsList",'action': "create",   "subject" : "plan" },
        { text: "CSP Reports",    value: "cspReports",       'action': "view",     "subject" : "cspReport" },
        { text: "Add Custom Page",value: "addCustomPage" ,   'action': "create",   "subject" : "customPages" },
        { text: "Custom Pages",   value: "customPages",      'action': "create",   "subject" : "customPages" },
        { text: "Faq",            value: "faqAdmin",         'action': "create",   "subject" : "FAQ" }
    ]
};

const getters = {
    format_date: ({ state }) => date => {
        return moment(date).format("MM/DD/YYYY");
    }
};

const actions = {
    /******************* /GET REQUEST GLOBAL ACTION\ ***************************/
    getRequest(context, item) {
        context.commit("SHOW_LOADER", true);
        axios.defaults.headers.common[
            "Authorization"
        ] = `${Vue.prototype.$accessToken()}`;
        axios.defaults.headers.common["Content-Type"] = "application/json";

        return new Promise((resolve, reject) => {
            axios
                .post(item.url)
                .then(response => {
                    context.commit("SHOW_LOADER", false);
                    resolve(response);
                    if (item.showMessage) {
                        context.commit("SHOW_SNACKBAR", {
                            show: true,
                            color: "primary",
                            message: response.data.message
                        });
                    }
                })
                .catch(error => {
                    context.commit("SHOW_LOADER", false);
                    reject(error);
                    // if (item.showMessage) {
                    context.commit("SHOW_SNACKBAR", {
                        show: true,
                        color: "red",
                        message: error.response
                            ? error.response.data.message
                            : "Something went Wrong!"
                    });
                    // }
                });
        });
    },

    /******************* /POST REQUEST GLOBAL ACTION\ ***************************/
    postRequest(context, item) {
        context.commit("SHOW_LOADER", true);
        let formData = new FormData();
        if (item.body) {
            item.body.forEach((value, key) => {
                formData.append(key, value);
            });
        }

        axios.defaults.headers.common[
            "Authorization"
        ] = `${Vue.prototype.$accessToken()}`;
        axios.defaults.headers.common["Content-Type"] = "application/json";

        return new Promise((resolve, reject) => {
            axios
                .post(item.url, formData, {})
                .then(response => {
                    context.commit("SHOW_LOADER", false);
                    resolve(response);
                    if (item.showMessage) {
                        context.commit("SHOW_SNACKBAR", {
                            show: true,
                            color: "primary",
                            message: response.data.message
                        });
                    }
                })
                .catch(error => {
                    context.commit("SHOW_LOADER", false);
                    reject(error);

                    // if (item.showMessage) {
                    context.commit("SHOW_SNACKBAR", {
                        show: true,
                        color: "red",
                        message: error.response
                            ? error.response.data.message
                            : "Something went Wrong!"
                    });
                    // }
                });
        });
    },

    actionloaderState(context, status) {
        context.commit("SHOW_LOADER", status);
    },

    successSnackBar(context, message) {
        context.commit("SHOW_SNACKBAR", {
            show: true,
            color: "primary",
            message: message
        });
    },
    errorSnackBar(context, message) {
        context.commit("SHOW_SNACKBAR", {
            show: true,
            color: "red",
            message: message
        });
    },

    //Used With Logs
    snackBarState(context, snackProp) {
        context.commit("SHOW_SNACKBAR", {
            show: snackProp.show,
            color: snackProp.color,
            message: snackProp.message
        });
    }
};

const mutations = {
    SHOW_LOADER(state, status) {
        state.loaderState = status;
    },
    SHOW_SNACKBAR(state, snack) {
        state.showSnackBar.message = snack.message;
        state.showSnackBar.color = snack.color;
        state.showSnackBar.show = snack.show;
    },

    UPDATE_TABS(state, tab) {
        switch (tab.type) {
            // General Settings Tabs
            case 0:
                {
                    state.tabs.generalTabs = tab.value;
                }
                break;
            // Company Tabs
            case 1:
                {
                    state.tabs.companyTabs = tab.value;
                }
                break;
            // Employee Tabs
            case 2:
                {
                    state.tabs.employeeTabs = tab.value;
                }
                break;

            // Logs Tabs
            case 3:
                {
                    state.tabs.logsTabs = tab.value;
                }
                break;
            // Logs Tabs
            case 4:
                {
                    state.tabs.faqTabs = tab.value;
                }
                break;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
