import axios from "axios";
import { reject } from "lodash";
import Cookies from "js-cookie";
const state = {
  urlStatus: 0,
  loaderstate: false,
  loginState: false,
  signupState: false,
  passwordStatus: false,
  token: "",
  showSnackBar: {
    show: false,
    message: "",
    color: "",
  },
  hasError: false,
  errorMessage: "",
};

const getters = {};

const actions = {
  reset_password(context, data) {
    context.commit("SHOW_LOADER", true);
    var api_url = "/api/merchant/reset_password";
    const form = new FormData();
    form.append("email_id", data.email);
    form.append("password", data.password);
    axios
      .post(api_url, form)
      .then((response) => {

        context.commit("SHOW_LOADER", false);
        context.commit("SHOW_SNACKBAR", {
          success: true,
          message: response.data.message,
        });
        context.commit("PASSWORD_STATUS", true);

      })
      .catch((error) => {
        context.commit("SHOW_LOADER", false);
        var errorMsg = "Something went wrong.";
        if (error.response.data.message) {
          errorMsg = error.response.data.message;
        }
        context.commit("SHOW_SNACKBAR", {
          success: false,
          message: errorMsg,
        });
      });
  },
  forgotPassword(context, data) {
    context.commit("SHOW_LOADER", true);
    var api_url = "/api/merchant/forgotPassword";
    const form = new FormData();

    form.append("email_id", data.email);
    axios
      .post(api_url, form)
      .then((response) => {


        context.commit("SHOW_SNACKBAR", {
          success: true,
          message: response.data.message,
        });
        context.commit("TRACK_URL", 1);

        context.commit("SHOW_LOADER", false);
      })
      .catch((error) => {
        context.commit("SHOW_LOADER", false);
        var errorMsg = "If an Account Exists , You will receive an email with link to reset your password..";
        context.commit("TRACK_URL", 2);
        if (error.response) {
          errorMsg = error.response.data.message;
        }
        context.commit("SHOW_SNACKBAR", {
          success: false,
          message: errorMsg,
        });
      });
  },


  change_merchant_password(context, data) {
    return new Promise((resolve, reject) => {

      context.commit("SHOW_LOADER", true);
      var api_url = "/api/merchant/change_merchant_password";
      const form = new FormData();
      form.append("password", data.oldPass);
      form.append("newpassword", data.newPass);
      form.append("GCtoken", data.GCtoken);

      if (data.merchant_id) {
        form.append("user_id", data.merchant_id);
      }

      axios
        .post(api_url, form)
        .then((response) => {

          resolve(response)

          context.commit("SHOW_SNACKBAR", {
            success: response.data.status == 200 ? true : false,
            message: response.data.message
          });

          context.commit("SHOW_LOADER", false);
        })
        .catch((error) => {
          reject(error)
          context.commit("SHOW_LOADER", false);
          context.commit("SHOW_SNACKBAR", {
            success: false,
            message: error.response ? error.response.data.message : "something went wrong!" ,
          });
        });
    })
  },

  save_shipping_address(context, data) {
    context.commit("SHOW_LOADER", true);
    var api_url = "/api/merchant/save_shipping_address";
    const form = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      form.append(key, value);
    });

    axios
      .post(api_url, form)
      .then((response) => {
        context.commit("SHOW_SNACKBAR", {
          success: response.data.status == 200 ? true : false,
          message: response.data.message
        });

        context.commit("SHOW_LOADER", false);
      })
      .catch((error) => {
        context.commit("SHOW_LOADER", false);
        context.commit("SHOW_SNACKBAR", {
          success: false,
          message: error.response.data.message,
        });
      });
  },

  get_shipping_address_by_merchant(context, data) {

    return new Promise((resolve, reject) => {

      context.commit("SHOW_LOADER", true);
      var api_url = "/api/merchant/get_shipping_address_by_merchant";
      const form = new FormData();
      axios
        .post(api_url, form)
        .then((response) => {

          resolve(response)

          context.commit("SHOW_SNACKBAR", {
            success: true,
            message: response.data.message
          });

          context.commit("SHOW_LOADER", false);
        })
        .catch((error) => {

          reject(error)
          context.commit("SHOW_LOADER", false);
          context.commit("SHOW_SNACKBAR", {
            success: false,
            message: error.response.data.message,
          });
        });


    });
  },



  verify_reset_password_token(context, data) {

    return new Promise((resolve, reject) => {
      context.commit("SHOW_LOADER", true);
      var api_url = "/api/merchant/verify_reset_password_token";
      const form = new FormData();
      form.append("token", data.token);
      form.append("GCtoken", data.GCtoken);
      axios
        .post(api_url, form)
        .then((response) => {

          resolve(response)

        })
        .catch((error) => {
          reject(error)
          context.commit("SHOW_LOADER", false);
          context.commit("SHOW_SNACKBAR", {
            success: false,
            message: error.response ? error.response.data.message : "Invalid Token.",
          });
        });
    })
  },

  loginMerchant(context, data) {

    return new Promise((resolve, reject) => {

      context.commit("SIGNUP_STATUS", false);
      context.commit("SHOW_LOADER", true);
      var api_url = "/api/login_merchant";
      const form = new FormData();
      form.append("password", data.password);
      form.append("email_id", data.email);
      form.append("GCtoken", data.GCtoken);
      axios
        .post(api_url, form)
        .then((response) => {
          let token = 'Bearer ' + response.data.data.token;
          axios.defaults.headers.common["Authorization"] = token;
          var remember = "";
          var now = new Date();
          now.setTime(now.getTime() + 1 * 3600 * 1000);
          Cookies.set('access_token', token, { expires: remember ? now : 1 });
          context.commit("MERCHANT_LOGIN_STATUS", true);
          context.commit("SHOW_LOADER", false);
          resolve(response)

        })
        .catch((error) => {



          context.commit("MERCHANT_LOGIN_STATUS", false);
          context.commit("SHOW_LOADER", false);
          context.commit("SHOW_SNACKBAR", {
            success: false,
            message: error.response ? error.response.data.message : "The Merchant credentials were incorrect.",
          });
          reject(error)
        });

    })
  },

  createMerchant(context, data) {
    return new Promise((resolve, reject) => {

      var api_url = "/api/merchant/create_merchant_account";
      context.commit("SHOW_LOADER", true);

      const form = new FormData();
      form.append("firstname", data.firstname);
      form.append("lastname", data.lastname);
      form.append("email_id", data.email_id);
      form.append("website", data.website);
      form.append("phone_number", data.phone_number);
      form.append("job_title", data.job_title);
      form.append("address", data.address);
      form.append("business_name", data.business_name);
      form.append("post_code", data.post_code);
      form.append("city", data.city);
      form.append("state", data.state);
      form.append("house_number", data.house_number);
      form.append("country", data.country);
      form.append("company_size", data.company_size);
      form.append("industry_sector", data.industry_sector);
      form.append("industry_subsector", data.industry_subsector);
      form.append("password", data.password);
      form.append("retype_password", data.retype_password);
      form.append("GCtoken", data.GCtoken)

      axios
        .post(api_url, form)
        .then((response) => {

          resolve(response)
          context.commit("SHOW_LOADER", false);
          context.commit("SIGNUP_STATUS", true);
          context.commit("SHOW_SNACKBAR", {
            success: true,
            message: "Signup Successfully",
          });

        })
        .catch((error) => {
          reject(error)
          context.commit("SHOW_LOADER", false);
          context.commit("SHOW_SNACKBAR", {
            success: false,
            message: error.response ? error.response.data.message : "Signup Failed!",
          });
        });

    })

  },
};
const mutations = {
  SHOW_LOADER(state, status) {
    state.loaderstate = status;
  },
  SIGNUP_STATUS(state, signupState) {
    state.signupState = signupState;
  },
  MERCHANT_LOGIN_STATUS(state, loginState) {
    // alert('M'+ loginState)
    state.loginState = loginState;
  },
  TRACK_URL(state, urlStatus) {
    state.urlStatus = urlStatus;
  },
  GET_TOKEN(state, token) {
    state.token = token;
  },
  PASSWORD_STATUS(state, passwordStatus) {
    state.passwordStatus = passwordStatus;
  },
  SHOW_SNACKBAR(state, payload) {
    state.showSnackBar.show = true;
    state.showSnackBar.message = payload.message;
    var color = "red";
    if (payload.success) {
      color = "primary";
    }
    state.showSnackBar.color = color;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
