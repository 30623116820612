<template>
  <div class="merchant-onb-sidebar">
    <v-row class="mb-0">
      <v-col class="pt-0" cols="12" sm="9" md="10">
        <v-carousel
          hide-delimiters
          v-model="carousel"
          :show-arrows="false"
          height="100%"
        >
          <v-carousel-item v-for="(item, i) in items" :key="i">
            <h3
              :style="'font-size: ' + item.textSize + ';'"
              class="sb-onb-title"
              v-html="item.title"
            ></h3>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col
        class="pt-0 d-flex justify-center justify-sm-end justify-md-end sb-onb-nav-arrow"
        align-self="end"
        cols="12"
        sm="3"
        md="2"
      >
        <v-icon :disabled="carousel == 0" color="black" @click="carousel--">
          mdi-arrow-left</v-icon
        >
        <v-icon color="black" class="ml-2" @click="carousel++">
          mdi-arrow-right</v-icon
        >
      </v-col>
    </v-row> 
    <v-carousel
      hide-delimiters
      v-model="carousel"
      class="v3-carousel"
      :height="getHeight()"
      :show-arrows="false"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <img
          :src="item.src"
          height="100%"
          width="100%"
          class="carousel-img-auth"
        />

        <img
          class="m-onb-pos-image scale-02sec"
          :src="item.heroImage"
          alt=" "
        />
      </v-carousel-item>
    </v-carousel>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  components: {},
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      carousel: 0,
      items: [
        {
          name: "SignIn",
          src: "/assets/v3_assets/merchant_ob_banner/banner-2.webp",
          title: "Dustid helps increase your</br>check out conversion rate",
          textSize: "2.3vw",
          heroImage: "/assets/v3_assets/merchant_ob_banner/m-circle-2.svg",
        },
        {
          name: "SignIn",
          src: "/assets/v3_assets/merchant_ob_banner/banner-1.webp",
          title: "With Dustid the right gift</br>receives the right address",
          textSize: "2.3vw",
          heroImage: "/assets/v3_assets/merchant_ob_banner/m-circle-1.svg",
        },

        {
          name: "SignIn",
          src: "/assets/v3_assets/merchant_ob_banner/banner-3.webp",
          title: "Up-to-date contact details,</br>however often life changes",
          textSize: "2.3vw",
          heroImage: "/assets/v3_assets/merchant_ob_banner/m-circle-3.svg",
        },
        // {
        //   name: "Merchant Testimonial",
        //   src: "/assets/v3_assets/merchant_ob_banner/banner-2.webp",
        //   title:
        //     "Losing a customer at the check out finish line had been a nightmare. I have noticed a significant up swing of completed orders since joining Dustid. ",
        //   textSize: "1.3vw",
        //   heroImage: "/assets/v3_assets/merchant_ob_banner/m-circle-2.svg",
        // },
      ],
    };
  },

  methods: {
    getHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "400px";
        case "sm":
          return "400px";
        case "md":
          return "65vh";
        case "lg":
          return "65vh";
        case "xl":
          return "65vh";
      }
    },
  },
};
</script>

<style scoped>
.fade-transition-enter-to,
.fade-transition-leave-to {
  animation-delay: 0s !important;
  transition-duration: 0s !important;
}
</style>
