import axios from "axios";
import { reject } from "lodash";

const state = {

    loaderstate: false,
    getMerchantlist: [],
    getMerchantprofile: Object,
    merchantKyclist: [],
    activatedMerchantList: [],
    pendingMerchantList: [],

    showSnackBar: {
        show: false,
        message: "",
        color: "",
    },
    hasError: false,
    errorMessage: "",
};

const getters = {};

const actions = {
    check_merchant_phone_no(context, data) {
        return new Promise((resolve , reject) => {
            
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/merchant/check_merchant_phone_no";
        const form = new FormData();

        form.append("phone_number", data.phone);
        form.append("GCtoken", data.GCtoken);
        
        axios
            .post(api_url, form)
            .then((response) => {
                resolve(response)
                //Vue.prototype.$printLog('merchant Phone res===>'+response.data);
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                reject(error)
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: error.response ? error.response.data.message : "Enter your valid Moble number!",
                });
                context.commit("SHOW_LOADER", false);
            });
        })

    },
    check_merchant_email(context, data) {
        context.commit("SHOW_LOADER", true);

        return new Promise((resolve , reject) => {
            
        var api_url = "/api/merchant/check_merchant_email";
        const form = new FormData();

        form.append("email_id", data.email);
        form.append("GCtoken", data.GCtoken);
        axios
            .post(api_url, form)
            .then((response) => {
                resolve(response)
                //Vue.prototype.$printLog('merchant email res===>'+response.data);
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                reject(error)
                
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: error.response ? error.response.data.message : "Something went wrong!",
                });
                context.commit("SHOW_LOADER", false);
            });

        })

    },
    deleteMerchant(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/deleteMerchant";
        const form = new FormData();
        form.append("id", data.id);
        axios
            .post(api_url, form)
            .then((response) => {
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                //Vue.prototype.$printLog('merchant deletion error===>', error);
                context.commit("SHOW_LOADER", false);
            });
    },

    getMerchantProfile(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("SHOW_LOADER", true);
            const form = new FormData();
            var api_url = "/api/merchant/getMerchantProfile";
            axios
                .post(api_url, form)
                .then((response) => {
                    context.commit("MERCHANT_UPDATE_PROFILE", response.data);
                    context.commit("SHOW_LOADER", false);

                    // this mutation is used for getmerchantById
                    context.commit("MERCHANT_UPDATE", response.data);

                    resolve(response.data);
                })
                .catch((error) => {
                    context.commit("SHOW_LOADER", false);
                    reject(false);
                });
        })
    },

    updateMerchantKyc(context, data) {
        //Vue.prototype.$printLog("=======>store file =>"+data.file_name);
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/merchant/updateMerchantKyc";
        const form = new FormData();
        form.append("type_name", data.type_name);
        form.append("file_name", data.file_name);
        form.append("doc_img_path", data.doc_img_path);
        form.append("merchant_id", data.merchant_id);
        form.append("GCtoken", data.GCtoken);
        form.append("doc_type_id", data.doc_type_id ?? '');
        axios
            .post(api_url, form)
            .then((response) => {
                context.commit("MERCHANT_KYC_UPDATE", response.data);
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Saved Successfully",
                });

                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                //(error);
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: "Create Failed",
                });

                context.commit("SHOW_LOADER", false);
            });
    },


    deleteMerchantKyc(context, id) {

        context.commit("SHOW_LOADER", true);
        var api_url = "/api/merchant/deleteMerchantKyc";
        const form = new FormData();
        form.append("id", id);
        axios
            .post(api_url, form)
            .then((response) => {
                context.commit("MERCHANT_KYC_UPDATE", response.data.data);
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: response.data.message
                });

                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                //(error);
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: "Deletion Failed",
                });

                context.commit("SHOW_LOADER", false);
            });
    },


    getmerchantKyc(context, merchant_id) {
        context.commit("SHOW_LOADER", true);
        
        var api_url = "/api/merchant/getmerchantKycById";
        const form = new FormData();
        if(merchant_id)
        form.append("merchant_id", merchant_id);

        axios
            .post(api_url, form)
            .then((response) => {
                context.commit("MERCHANT_KYC_UPDATE", response.data);
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },

    updateMerchant(context, data) { 
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/merchant/updateMerchant";

        axios
            .post(api_url, data)
            .then((response) => {
              
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Update Successfully",
                });

                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                //(error);
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: "Create Failed!",
                });

                context.commit("SHOW_LOADER", false);
            });
    },

    getmerchant(context, merchant_id) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/merchant/getmerchantById";
        const form = new FormData();
        if(merchant_id)
        form.append("merchant_id", merchant_id);
        axios
            .post(api_url, form)
            .then((response) => {
                context.commit("MERCHANT_UPDATE", response.data);

                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },
    enable_merchant(context, data) {
        context.commit("SHOW_LOADER", true);

        var api_url = "/api/admin/enabled_merchant";
        const form = new FormData();
        form.append("isVerified", data.isVerified);
        form.append("id", data.id);
        axios
            .post(api_url, form)
            .then((response) => {

                context.commit("ACTIVATED_MERCHANT_UPDATE", response.data.data.active);
                context.commit("PENDING_MERCHANT_UPDATE", response.data.data.pending);
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Status Changed",
                });
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: "Status Change Failed!",
                });
                context.commit("SHOW_LOADER", false);
            });
    },
    activated_merchant(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/activated_merchant";
        axios
            .get(api_url)
            .then((response) => {
                context.commit("SHOW_LOADER", false);
                context.commit("ACTIVATED_MERCHANT_UPDATE", response.data);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                //(error);
            });
    },
    pending_merchant(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/pending_merchant";
        axios
            .get(api_url)
            .then((response) => {
                context.commit("SHOW_LOADER", false);
                context.commit("PENDING_MERCHANT_UPDATE", response.data);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },
    create_merchant(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/admin_create_merchant_account";
        const form = new FormData();
        form.append("is_admin_request", true) // admin request
        form.append("firstname", data.firstName);
        form.append("lastname", data.lastName);
        form.append("email_id", data.email);
        form.append("website", data.website);
        form.append("business_name", data.business_name);
        form.append("password", data.password);
        form.append("phone_number", data.phone);

        axios
            .post(api_url, form)

            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Create Successfully",
                });
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                //(error);
                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: "Create Failed!",
                });
            });
    },
};

const mutations = {
    SHOW_LOADER(state, status) {
        state.loaderstate = status;
    },
    SHOW_SNACKBAR(state, payload) {
        state.showSnackBar.show = true;
        state.showSnackBar.message = payload.message;
        var color = "red";
        if (payload.success) {
            color = "primary";
        }
        state.showSnackBar.color = color;
    },
    MERCHANT_UPDATE(state, getMerchantlist) {
        state.getMerchantlist = getMerchantlist;
    },
    MERCHANT_UPDATE_PROFILE(state, getMerchantprofile) {
        state.getMerchantprofile = getMerchantprofile;
    },
    MERCHANT_KYC_UPDATE(state, merchantKyclist) {
        state.merchantKyclist = merchantKyclist;
    },

    ACTIVATED_MERCHANT_UPDATE(state, activatedMerchantList) {
        state.activatedMerchantList = activatedMerchantList;
    },
    PENDING_MERCHANT_UPDATE(state, pendingMerchantList) {
        state.pendingMerchantList = pendingMerchantList;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};