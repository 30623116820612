<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">Merchant <span>Registration</span></h4>
          <br />
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img class="v3-logo" :src="'/assets/img/logo.png'" alt="Logo" />

          <p class="v3-sub-title">One app - all your connections</p>
          <h2 class="mt-2">Almost there</h2>

          <v-row class="zero-flex" justify="center">
            <v-col cols="12" sm="12" md="8" lg="7">
              <div>
                <p>Your Company</p>
                <v-row justify="center">
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      class="h50-field"
                      placeholder="Company Name"
                      outlined
                      v-model="merchant.business_name"
                      :error-messages="companyNameErrors"
                      @input="$v.merchant.business_name.$touch()"
                      @blur="$v.merchant.business_name.$touch()"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      placeholder="Url"
                      v-model="merchant.website"
                      :error-messages="urlErrors"
                      @input="$v.merchant.website.$touch()"
                      @blur="$v.merchant.website.$touch()"
                      required
                      outlined
                      class="h50-field"
                    ></v-text-field>
                  </v-col>

                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-select
                      background-color="white"
                      v-model="merchant.company_size"
                      :error-messages="companySizeErrors"
                      @input="$v.merchant.company_size.$touch()"
                      @blur="$v.merchant.company_size.$touch()"
                      required
                      class="h50-field"
                      :items="companySize"
                      placeholder="Company Size"
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-select
                      background-color="white"
                      dense
                      :items="industry_sector"
                      class="h50-field"
                      placeholder="Industry Sector"
                      outlined
                      v-model="merchant.industry_sector"
                      :error-messages="industrySectorErrors"
                      @input="$v.merchant.industry_sector.$touch()"
                      @blur="$v.merchant.industry_sector.$touch()"
                      required
                    ></v-select>
                  </v-col>

                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-select
                      background-color="white"
                      :items="industry_subsector"
                      class="h50-field"
                      placeholder="Industry Sub-Sector"
                      outlined
                      v-model="merchant.industry_subsector"
                      :error-messages="industrySubSectorErrors"
                      @input="$v.merchant.industry_subsector.$touch()"
                      @blur="$v.merchant.industry_subsector.$touch()"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
                <div>
                  <v-btn
                    :loading="saveLoading"
                    class="primary-bg-line-btn mt-3"
                    outlined
                    block
                    large
                    @click="compnayContinue('company')"
                  >
                    Continue
                  </v-btn>
                </div>
              </div>

              <br />
              <br />
              <div class="merchant-step-area">
                <p>3/4</p>
                <img
                  :src="'/assets/v3_assets/custom_shapes/m_step_3.png'"
                  alt="Logo"
                />
              </div>

              <div @click="goBack()" class="auth-back-btn i">
                <v-btn class="v3-div-clr" elevation="0" depressed flex>
                  <img
                    class="v3-back-btn"
                    :src="'/assets/v3_assets/back_btn.svg'"
                  />
                  Back
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
import MerchantBannerWidget from "../auth/MerchantBannerWidget.vue";
import axios from "axios";
export default {
  props: ["userInfo"],
  components: {
    MerchantBannerWidget,
  },
  created() {
    this.$store.dispatch("companySize", {});
    this.$store.dispatch("industry_sector", {});
    this.$store.dispatch("industry_subsector", {});

    if (this.userInfo) {
      var item = this.userInfo;
      this.merchant.business_name = item.business_name;
      this.merchant.website = item.website;
      this.merchant.company_size = item.company_size;
      this.merchant.industry_sector = item.industry_sector;
      this.merchant.industry_subsector = item.industry_subsector;
    } else {
      this.$router.replace("/v3/merchant/signup");
    }
  },
  computed: {
    ...mapState({}),
    companyNameErrors() {
      const errors = [];
      if (!this.$v.merchant.business_name.$dirty) return errors;
      !this.$v.merchant.business_name.required &&
        errors.push("Company Name is required.");
      return errors;
    },
    companySizeErrors() {
      const errors = [];
      if (!this.$v.merchant.company_size.$dirty) return errors;
      !this.$v.merchant.company_size.required &&
        errors.push("Company Size is required.");
      return errors;
    },

    urlErrors() {
      const errors = [];
      if (!this.$v.merchant.website.$dirty) return errors;
      !this.$v.merchant.website.required && errors.push("Url is required.");
      !this.isValidUrl(this.merchant.website) &&
        errors.push("Enter valid domain!");
      return errors;
    },

    industrySectorErrors() {
      const errors = [];
      if (!this.$v.merchant.industry_sector.$dirty) return errors;
      !this.$v.merchant.industry_sector.required &&
        errors.push("Industry Sector is required.");
      return errors;
    },

    industrySubSectorErrors() {
      const errors = [];
      if (!this.$v.merchant.industry_subsector.$dirty) return errors;
      !this.$v.merchant.industry_subsector.required &&
        errors.push("Industry Subsector is required.");
      return errors;
    },
    documentsList: function () {
      return this.$store.state.custom.documentsList.map((e) => {
        return {
          text: e.doc,
          value: e.id,
        };
      });
    },
    companyStatus: function () {
      return this.$store.state.custom.companyStatusList.map((e) => {
        return {
          text: e.status,
          value: e.id,
        };
      });
    },

    industry_subsector: function () {
      return this.$store.state.custom.industry_subsectorList.map((e) => {
        return {
          text: e.name,
          value: e.id,
        };
      });
    },
    industry_sector: function () {
      return this.$store.state.custom.industry_sectorList.map((e) => {
        return {
          text: e.name,
          value: e.id,
        };
      });
    },
    companySize: function () {
      return this.$store.state.custom.companySizeList.map((e) => {
        return {
          text: e.size,
          value: e.id,
        };
      });
    },
  },

  data: () => ({
    saveLoading: false,
    e1: 1,
    merchant: {
      business_name: "",
      company_size: "",
      industry_sector: "",
      industry_subsector: "",
      website: "",
    },
    snackbar: {
      show: false,
      message: "",
      color: null,
    },
  }),
  validations: {
    merchant: {
      business_name: { required },
      company_size: { required },
      industry_sector: { required },
      industry_subsector: { required },
      website: { required },
    },
  },
  methods: {
    isValidUrl(urlString) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$", // fragment locator
        "i"
      );
      return pattern.test(urlString);
    },
    goBack() {
      this.$router.push({
        name: "merchantRegisterYourAddress",
        params: { userInfo: this.userInfo },
      });
    },
    async compnayContinue(value) {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.snackbar = {
          message: "Fill in all the fields",
          color: "red",
          show: true,
        };
        this.validate = false;
      } else {
        const form = new FormData();
        form.append("business_name", this.merchant.business_name);
        form.append("company_size", this.merchant.company_size);
        form.append("industry_sector", this.merchant.industry_sector);
        form.append("industry_subsector", this.merchant.industry_subsector);
        form.append("merchant_id", this.userInfo.id);
        form.append("website", this.merchant.website);
        form.append("section", value);
        await this.$recaptchaLoaded();
        const GCtoken = await this.$recaptcha("merchantRegisterYourCompany");
        form.append("GCtoken", GCtoken);
        this.saveLoading = true;
        axios
          .post("/api/v3/merchant_register", form)
          .then((response) => {
            this.saveLoading = false;
            this.$router.push({
              name: "merchantRegisterSetupPassword",
              params: { userInfo: response.data.data },
            });
          })
          .catch((e) => {
            this.saveLoading = false;
            this.snackbar = {
              message: e.response.data.message,
              color: "red",
              show: true,
            };
             this.$router.replace("/v3/merchant/signup");
          });
      }
    },
  },
};
</script>

<style scoped>
.v-stepper__header {
  box-shadow: none !important;
}
</style>
