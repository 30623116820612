<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">User <span>Registration</span></h4>
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img
            class="v3-logo"
            :src="'/assets/img/logo.png'"
            alt="Logo"
          />
          <p class="v3-sub-title">One app, All your connections</p>
          <h2>Let's get <br />you started!</h2>

          <br /><br />
          <p class="v3-sub-p">Please enter your mobile number</p>

          <v-row class="zero-flex" justify="center">
            <v-col cols="12" sm="12" md="7" lg="6">
              <v-row class="mx-0">
                <v-col class="pr-0 pr-md-2" cols="4" md="4">
                  <v-select
                    :items="country_codes"
                    label="Country code"
                    outlined
                    background-color="white"
                    class="h50-field"
                    v-model="user.country_code"
                  ></v-select>
                  <!-- :disabled="loaderstate" -->
                </v-col>
                <v-col cols="8" md="8">
                  <v-text-field
                    background-color="white"
                    v-model="user.mobile_number"
                    placeholder="Enter your Mobile Number"
                    outlined
                    :error-messages="mobileError"
                    @input="$v.user.mobile_number.$touch()"
                    @blur="$v.user.mobile_number.$touch()"
                    required
                    class="h50-field"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-btn
                class="primary-bg-line-btn"
                :loading="loader"
                @click="emailVerfication()"
                outlined
                block
                large
                depressed
              >
                Register with mobile number
              </v-btn>
            </v-col>
          </v-row>

          <div class="v3-auth-sub-footer">
            <p class="text-3">
              Already have an account?
              <span @click="signIn">Sign In</span>
            </p>
          </div>

          <v-snackbar v-model="snackbar.show" :color="snackbar.color">
            {{ snackbar.message }}

            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>

          <div @click="goBack()" class="auth-back-btn">
            <v-btn class="v3-div-clr" elevation="0" depressed flex>
              <v-icon dark size="20px"> mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
  
  <script>
import {
  required,
  minLength,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import MerchantBannerWidget from "../../merchant/auth/MerchantBannerWidget.vue";
import axios from "axios";
export default {
  components: {
    MerchantBannerWidget,
  },
  computed: {
    ...mapState({}),
    mobileError() {
      const errors = [];
      if (!this.$v.user.mobile_number.$dirty) return errors;
      !this.$v.user.mobile_number.required &&
        errors.push("mobile number is required.");
      !this.$v.user.mobile_number.minLength &&
        errors.push("mobile number must be at least 10 digits.");
      !this.$v.user.mobile_number.maxLength &&
        errors.push("mobile number must be at least 10 digits.");
      if (this.user.mobile_number.length > 9) {
      } else {
        errors.push("Enter Valid mobile number");
        return errors;
      }

      return errors;
    },
  },

  data: () => ({
    mobile_otp_send: false,
    mobile_verify_otp: "",
    mobile_verify: false,
    mobiole_resendFlag: false,
    mobile_timeLeft: 30,
    mobile_timerId: null,
    terms: "",
    country_codes: ["+91", "+44"],
    terms_conditions: false,
    dialog: false,
    show1: false,
    show2: false,
    loader: false,
    snackbar: {
      show: false,
      message: "",
      color: null,
    },

    user: {
      mobile_number: "",
      country_code: "+44",
      createUser: true,
    },
    termsDialog: false,
  }),
  validations: {
    user: {
      mobile_number: {
        maxLength: maxLength(10),
        minLength: minLength(10),
        required,
      },
    },
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    signIn() {
      this.$router.push({ name: "userSignIn" });
    },

    async emailVerfication() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        this.snackbar = {
          message: "Please enter your Valid Mobile NUmber",
          color: "red",
          show: true,
        };
      } else {
        this.mobile_timeLeft = 30;
        this.mobiole_resendFlag = false;

        if (!this.user.mobile_number) {
          var msg = "Please enter your Valid MObile Number";
          this.errorSnackBar(msg);
          return false;
        }

        const form = new FormData();
        //   form.append("email", this.user.mobile_number);
        form.append("phone", this.user.mobile_number);
        form.append("type", 'web');
        form.append("country_code", this.user.country_code);
        form.append("verify_merchant", true);
        await this.$recaptchaLoaded();
        const GCtoken = await this.$recaptcha("merchantRegister");
        form.append("GCtoken", GCtoken);
        this.loader = true;
        axios
          .post("/api/v3/webUserSignUp", form)
          .then((response) => {
            this.loader = false;
            if (response.data.status == 200) {
              this.$router.push({
            name: "userOtp",
            params: { userInfo: this.user },
          });
              this.mobile_timerId = setTimeout(this.email_countdown, 1000);
              this.mobile_otp_send = true;
              this.existing_account = response.data.existing_account;
            } else {
              this.mobile_otp_send = false;

              this.errorSnackBar(response.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = {
              message: e.response.data.message,
              color: "red",
              show: true,
            };
            this.loader = false;
            this.mobile_otp_send = false;
            this.errorSnackBar(e.response.data.message);
          });
      }
    },

    errorSnackBar(msg) {
      this.snackbar.show = true;
      this.snackbar.color = "error";
      this.snackbar.message = msg;
    },
  },
};
</script>
  