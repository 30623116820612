<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">Merchant <span>Registration</span></h4>
          <br />
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img class="v3-logo" :src="'/assets/img/logo.png'" alt="Logo" />
          <p class="v3-sub-title">One app - all your connections</p>
          <h2>Let's get <br />you started!</h2>

          <br /><br />
          <p class="v3-sub-p">Please enter your email</p>

          <v-row class="zero-flex" justify="center">
            <v-col cols="12" sm="12" md="7" lg="6">
              <v-text-field
                background-color="white"
                v-model="merchant.email_id"
                placeholder="Enter your email"
                outlined
                :error-messages="emailErrors"
                @input="$v.merchant.email_id.$touch()"
                @blur="$v.merchant.email_id.$touch()"
                required
                class="h50-field"
              ></v-text-field>

              <v-btn
                class="primary-bg-line-btn"
                :loading="loader"
                @click="emailVerfication()"
                outlined
                block
                large
                depressed
              >
                Register with email
              </v-btn>
            </v-col>
          </v-row>

          <div class="v3-auth-sub-footer">
            <p class="text-3">
              Already have an account?
              <span @click="signIn()">Sign In</span>
            </p>
          </div>

          <v-snackbar v-model="snackbar.show" :color="snackbar.color">
            {{ snackbar.message }}

            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>

          <div @click="goBack()" class="auth-back-btn i">
            <v-btn class="v3-div-clr" elevation="0" depressed flex>
              <img
                class="v3-back-btn"
                :src="'/assets/v3_assets/back_btn.svg'"
              />
              <span> Back</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import MerchantBannerWidget from "../auth/MerchantBannerWidget.vue";
import axios from "axios";
export default {
  components: {
    MerchantBannerWidget,
  },
  computed: {
    ...mapState({}),
    emailErrors() {
      const errors = [];
      if (!this.$v.merchant.email_id.$dirty) return errors;
      !this.$v.merchant.email_id.required && errors.push("Email is required.");
      !this.$v.merchant.email_id.email && errors.push("Email must be valid.");
      return errors;
    },
  },
  data: () => ({
    email_otp_send: false,
    email_verify_otp: "",
    email_verify: false,
    email_resendFlag: false,
    email_timeLeft: 30,
    email_timerId: null,
    terms: "",
    terms_conditions: false,
    dialog: false,
    show1: false,
    show2: false,
    loader: false,
    snackbar: {
      show: false,
      message: "",
      color: null,
    },

    merchant: {
      email_id: "",
      country_code: "",
      phone_number: "",
    },
    termsDialog: false,
  }),
  validations: {
    merchant: {
      email_id: {
        email,
        required,
      },
    },
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    signIn() {
      this.$router.push({ name: "merchantSignIn" });
    },

    async emailVerfication() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        this.snackbar = {
          message: "Please enter your Valid Email",
          color: "red",
          show: true,
        };
      } else {
        this.email_timeLeft = 30;
        this.email_resendFlag = false;

        if (!this.merchant.email_id) {
          var msg = "Please enter your Valid Email";
          this.errorSnackBar(msg);
          return false;
        }

        const form = new FormData();
        form.append("email", this.merchant.email_id);
        form.append("phone", this.merchant.phone_number);
        form.append("verify_merchant", true);
        await this.$recaptchaLoaded();
        const GCtoken = await this.$recaptcha("merchantRegister");
        form.append("GCtoken", GCtoken);
        this.loader = true;
        axios
          .post("/api/v3/email_send_signup_otp_api", form)
          .then((response) => {
            this.loader = false;
            if (response.data.status == 200) {
              this.$router.push({
                name: "merchantOtp",
                params: { email: this.merchant.email_id },
              });
              this.email_timerId = setTimeout(this.email_countdown, 1000);
              this.email_otp_send = true;
              this.existing_account = response.data.existing_account;
            } else {
              this.email_otp_send = false;

              this.errorSnackBar(response.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = {
              message: e.response.data.message,
              color: "red",
              show: true,
            };
            this.loader = false;
            this.email_otp_send = false;
            this.errorSnackBar(e.response.data.message);
          });
      }
    },

    errorSnackBar(msg) {
      this.snackbar.show = true;
      this.snackbar.color = "error";
      this.snackbar.message = msg;
    },
  },
};
</script>
