import { reject } from 'lodash'
import Vue from 'vue'
import axios from "axios";

const state = {
    status: '',
    profile: {
        name : ""
    }
}

const getters = {
    getProfile: state => state.profile,
    isProfileLoaded: state => !!state.profile.name,
}

const actions = {
    
    userRequest: ({ commit, dispatch }) => {
        // return new Promise((resolve , reject) => {
        commit('userRequest')
        axios.get('/api/user')
            .then((resp) => {
                commit('userSuccess', resp.data);
               
            })
            .catch((err) => {
                commit('userError');
                dispatch('authLogout')
            })
    // })
    },
}

const mutations = {
    userRequest: (state) => {
        state.status = 'loading';
    },
    userSuccess: (state, resp) => {
       
        state.status = 'success';
        state.profile.name = resp ;
        // Vue.set(state, 'profile', resp);
    },
    userError: (state) => {
        state.status = 'error';
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}