import axios from "axios";

const state = {
    loaderstate: false,
    loginState: false,
    companySizeList: [],
    industry_sectorList: [],
    industry_subsectorList: [],
    companyStatusList: [],
    documentsList: [],
    countries:[]
}

const getters = {

}

const actions = {
    companySize(context) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/merchant/companySize";
        axios
            .get(api_url)
            .then(response => {
                // Vue.prototype.$printLog("==================companySize===>", response.data);
                context.commit("SHOW_LOADER", false);

                context.commit("COMPANY_SIZE", response.data);

            })
            .catch(error => {
                //(error);
                context.commit("SHOW_LOADER", false);
            });

    },

    industry_sector(context) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/merchant/industry_sector";
        axios
            .get(api_url)
            .then(response => {
                // Vue.prototype.$printLog("==================response.data===>", response.data);

                context.commit("INDUSTRY_SECTOR", response.data);
                context.commit("SHOW_LOADER", false);

            })
            .catch(error => {
                //(error);
                context.commit("SHOW_LOADER", false);
            });

    },

    industry_subsector(context) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/merchant/industry_subsector";
        axios
            .get(api_url)
            .then(response => {
                // Vue.prototype.$printLog("==================response.data===>", response.data);

                context.commit("INDUSTRY_SUB_SECTOR", response.data);
                context.commit("SHOW_LOADER", false);

            })
            .catch(error => {
                //(error);
                context.commit("SHOW_LOADER", false);
            });

    },
    companyStatus(context) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/merchant/companyStatus";
        axios
            .get(api_url)
            .then(response => {
                // Vue.prototype.$printLog("==================response.data===>", response.data);

                context.commit("COMPANY_STATUS", response.data);
                context.commit("SHOW_LOADER", false);

            })
            .catch(error => {
                //(error);
                context.commit("SHOW_LOADER", false);
            });

    },
    kycDocuments(context) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/merchant/kycDocuments";
        axios
            .get(api_url)
            .then(response => {
                Vue.prototype.$printLog("==================kycDocuments.data===>", response.data);

                context.commit("DOCUMENTS", response.data);
                context.commit("SHOW_LOADER", false);

            })
            .catch(error => {
                //(error);
                context.commit("SHOW_LOADER", false);
            });

    },

    getCountries(context , data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/getCountries";
      //  var flag =  data.is_name ? true : false ;
        axios
            .get(api_url)
            .then(response => { 

                var countries = [];
 
                response.data.data.forEach(element => {
                     countries.push({
                         text: element.country,
                         value: element.id
                     });
                });

                context.commit("SET_COUNTRIES", countries);
                
                context.commit("SHOW_LOADER", false);
            })
            .catch(error => {
                context.commit("SHOW_LOADER", false);
            });

    },


}
const mutations = {
    SHOW_LOADER(state, status) {
        state.loaderstate = status;
    },
    COMPANY_SIZE(state, companySizeList) {
        state.companySizeList = companySizeList;

    },

    SET_COUNTRIES(state, list) {
        state.countries = list;

    },
    //

    INDUSTRY_SECTOR(state, industry_sectorList) {
        state.industry_sectorList = industry_sectorList;

    },
    INDUSTRY_SUB_SECTOR(state, industry_subsectorList) {
        state.industry_subsectorList = industry_subsectorList;

    },
    COMPANY_STATUS(state, companyStatusList) {
        state.companyStatusList = companyStatusList;

    },
    DOCUMENTS(state, documentsList) {
        state.documentsList = documentsList;

    },
    //

}
export default {
    state,
    getters,
    actions,
    mutations,
}