/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from "vue";
require("./bootstrap");
import "@mdi/font/css/materialdesignicons.css";
window.Vue = require("vue").default;
import { router } from "./router/routes";
import { store } from "./vuex/store";
import Vuetify from "../../plugins/vuetify";
import Vuelidate from "vuelidate";
import VueYoutube from "vue-youtube";
//TODO: Fix this import : check error in console
import * as firebase from "firebase";
import moment from "moment";
import VueMoment from "vue-moment";
Vue.use(VueMoment);

import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import Axios from "axios";
window.axios = Axios;

import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6LctHuAUAAAAAOAZglZNkMOLjoxiTebA1-bFq41E" });

import * as VueGoogleMaps from "vue2-google-maps";
import common_constants from "./constants/common_constants";
import Cookies from "js-cookie";

Vue.use(moment);
Vue.use(VueQuillEditor /* { default global options } */);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.MIX_GOOGLE_MAP_KEY, //AIzaSyD1FoQBcetgpAP279xtg8BA-sRf6aTLrl4
    libraries: "places",
  },
});


Vue.prototype.$constants = common_constants;

Vue.use(Vuelidate);
Vue.use(VueYoutube);
/**
 * Get Logined user auth token
 */
Vue.prototype.$userAccessToken = function () {
  return Cookies.get("access_token") || "";
};
/**
 * print console log
 */
Vue.prototype.$printLog = function (message, data) {
};
 
const firebaseConfig = {
  apiKey: "AIzaSyAaf-KrVIhS7Bt5wEc4u3XR6xWM8cFy2YQ",
  authDomain: "dusitidv2.firebaseapp.com",
  databaseURL: "https://dusitidv2.firebaseio.com",
  projectId: "dusitidv2",
  storageBucket: "dusitidv2.appspot.com",
  messagingSenderId: "432284584907",
  appId: "1:432284584907:web:d26cc46b255076e899c44e",
  measurementId: "G-BZ34FYSKVR",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const app = new Vue({
  vuetify: Vuetify,
  el: "#app",
  created() {
    Axios.defaults.headers.common["Authorization"] = this.$userAccessToken();
    Axios.defaults.headers.common["Content-Type"] = "application/json";
    Axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            if (Cookies.get("access_token")) {
              Cookies.remove("access_token");
            }
            if (
              router.name == "login" ||
              router.name == "userSignIn" ||
              router.name == "merchantSignIn"
            ) {
            } else {
              router.replace({ name: "home" });
            }
          }
        }

        throw error;
      }
    );
  },

  store,
  router,
  components: {
    "vue-preloader": Vue.component("Preloader", () => {
      return import("./frontend/PreLoader.vue");
    }),

    "website-hold-page": Vue.component("LandingPage", () => {
      return import("./frontend/WebsiteHold.vue");
    }),

    "merchant-page-component": Vue.component("MerchantLanding", () => {
      return import(
        "./v3_pages/merchant/MerchantLanding.vue"
      );
    }),

    "shopper-page-component": Vue.component("ShopperLanding", () => {
      return import(
        "./v3_pages/shopper/ShopperLanding.vue"
      );
    }),

    "legals-page-component": Vue.component("LandingPage", () => {
      return import(
        "./v3_pages/merchant/sections/LegalsPage.vue"
      );
    }),

    "landing-page-component": Vue.component("LandingPage", () => {
      return import("./v3_pages/user_v3/HomeV3.vue");
    }),

    "faq-page-component": Vue.component("FaqPage", () => {
      return import("./frontend/Faq.vue");
    }),

    // TODO: This Include Component Comment 
    "about_us-page-component": Vue.component("aboutUsPage", () => {
      return import("./frontend/AboutUs.vue");
    }),

    // TODO: This Include Component Comment 
    "contact_us-page-component": Vue.component("contactUsPage", () => {
      return import("./frontend/ContactUs.vue");
    }),

    "individual-page-component": Vue.component("individualPage", () => {
      return import("./frontend/Individual.vue");
    }),

     // TODO: This Include Component Comment
    "customer_acquisition-page-component": Vue.component(
      "customerAcqPage",
      () => {
        return import("./frontend/CustomerAcq.vue");
      }
    ),

    "retailer_service-page-component": Vue.component(
      "retailerServicePage",
      () => {
        return import("./frontend/RetailerService.vue");
      }
    ),
    "our_retailer_partners-page-component": Vue.component(
      "ourRetailerPartnersPage",
      () => {
        return import("./frontend/RetailerPartners.vue");
      }
    ),

    // TODO: Comment This Api Hit Vue
    "download_plugin-page-component": Vue.component(
      "downloadPluginPage",
      () => {
        return import("./frontend/DownloadPlugin.vue");
      }
    ),

    // V3 Components
    home_component: Vue.component("HomeComponent", () => {
      return import("./v3_pages/user_v3/HomeV3.vue");
    }),

    user_signin_component: Vue.component("SignInComponent", () => {
      return import("./v3_pages/user_v3/auth_login/UserSignIn.vue");
    }),

    "contact3-page-component": Vue.component("ContentComponent", () => {
      return import("./v3_pages/user_v3/ContactUsV3.vue");
    }),
  },
});
