import axios from "axios";

const state = {
    //
    loaderstate: false,
    getUserprofile: Object,
    userAddress: Object,
    userMobileNumber: Object,
    addressType: [],
    users_birthday: [],
    registeredUser: [],
    preRegisterUsers: [],
    users_mobiles: [],
    register_logs: [],
    reminder_logs: [],
    friends_list: [],
    txn_history: [],
    loginState: false,
    signupState: false,
    showSnackBar: {
        show: false,
        message: "",
        color: "",
    },
    hasError: false,
    errorMessage: "",
    user_id: 0,
};

const getters = {};

const actions = {
    // 
    upload_profile_default(context, data) {
        context.commit("SHOW_LOADER", true);

        var api_url = "/api/admin/upload_profile_default";
        const form = new FormData();

        // return;
        form.append("image", data.image);
        form.append("id", data.id);
        form.append("item_key", data.item_key);
        axios
            .post(api_url, form)
            .then((response) => {
                //Vue.prototype.$printLog("========> Uploaded   =>"+response.data);
                context.commit("SHOW_LOADER", false);
                context.commit("SETTINGS", response.data.data, { root: true });
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Uploaded..",
                });
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Upload Failed..",
                });
            });
    },
    //
    snackBarState(context, data) {
        context.commit("SHOW_SNACKBAR", {
            success: true,
            message: "Cookies Cleared",
        });
    },
    send_msg(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/send_msg";

        const form = new FormData();
        form.append("user_id", data.user_id);
        form.append("msgTitle", data.msgTitle);
        form.append("msgBody", data.msgBody);

        axios
            .post(api_url, form)
            .then((response) => {

                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Message Send",
                });
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Messages Failed..",
                });
            });
    },

    send_msg_all_users(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/send_msg_all_users";

        const form = new FormData();
        form.append("msgTitle", data.msgTitle);
        form.append("msgBody", data.msgBody);

        axios
            .post(api_url, form)
            .then((response) => {

                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Message Send",
                });
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Messages Failed..",
                });
            });
    },

    getUsersMobileNumbers(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/user_mobile_no";
        const form = new FormData();

        form.append("user_id", data.info_id);
        axios
            .post(api_url, form)
            .then((response) => {
                var success_statuses = [200, 201, 202, 203, 204, 205];
                if (success_statuses.includes(response.data.status)) {
                    context.commit("USER_MOBILES_DATA", response.data.data);

                } else { }
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },
    userAddressTypes(context) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/user/userAddressTypes";
        axios
            .get(api_url)
            .then((response) => {
                context.commit("SHOW_LOADER", false);

                var success_statuses = [200, 201, 202, 203, 204, 205];
                if (success_statuses.includes(response.data.status)) {
                    context.commit("USER_ADDRESS_TYPE_DATA", response.data.data);

                } else { }
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },
    getRegisteredUsers(context, data) {

        return new Promise((resolve, reject) => {

            context.commit("SHOW_LOADER", true);
            var form = new FormData();
            form.append('page', data.options.page);
            form.append('page_size', data.options.itemsPerPage);
            form.append('search', data.search);
            form.append('search_type', data.search_type);
            form.append('web_type', 'web');

            var api_url = "/api/admin/registered_users";
            axios
                .post(api_url, form)
                .then((response) => {

                    resolve(response);
                    var success_statuses = [200, 201, 202, 203, 204, 205];
                    if (success_statuses.includes(response.data.status)) {
                        context.commit("REG_USER_DATA", response.data.data.data);

                    } else { }
                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    reject(error)
                    context.commit("SHOW_LOADER", false);
                });
        });
    },

    getPreRegisteredUsers(context, data) {
        return new Promise((resolve, reject) => {

            context.commit("SHOW_LOADER", true);

            var form = new FormData();
            form.append('page', data.options.page);
            form.append('page_size', data.options.itemsPerPage);
            form.append('search', data.search);
            form.append('search_type', data.search_type);
            var api_url = "/api/admin/users_view";
            axios
                .post(api_url, form)
                .then((response) => {
                    resolve(response)
                    context.commit("PRE_REG_USER_DATA", response.data.data.data);
                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    reject(error)
                    context.commit("SHOW_LOADER", false);
                });
        });
    },

    get_register_logs(context, data) {

        return new Promise((resolve, reject) => {

            context.commit("SHOW_LOADER", true);
            var form = new FormData();
            form.append('page', data.options.page);
            form.append('page_size', data.options.itemsPerPage);
            form.append('search', data.search);

            if (data.user_id) {
                form.append('user_id', data.user_id)
            }

            var api_url = "/api/admin/register_logs";
            axios
                .post(api_url, form)
                .then((response) => {

                    resolve(response);
                    context.commit("REG_LOGS", response.data.data.data);
                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    reject(error)
                    context.commit("SHOW_LOADER", false);
                });
        });
    },

    get_reminder_logs(context, data) {

        return new Promise((resolve, reject) => {

            context.commit("SHOW_LOADER", true);
            var form = new FormData();
            form.append('page', data.options.page);
            form.append('page_size', data.options.itemsPerPage);
            form.append('search', data.search);

            if (data.user_id) {
                form.append('user_id', data.user_id)
            }

            var api_url = "/api/admin/reminder_logs";
            axios
                .post(api_url, form)
                .then((response) => {

                    resolve(response);
                    context.commit("REMINDER_LOGS", response.data.data.data);
                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    reject(error)
                    context.commit("SHOW_LOADER", false);
                });
        });
    },



    get_friends_list(context, data) {

        return new Promise((resolve, reject) => {

            context.commit("SHOW_LOADER", true);
            var form = new FormData();
            form.append('page', data.options.page);
            form.append('page_size', data.options.itemsPerPage);
            form.append('search', data.search);
            if (data.user_id) {
                form.append('user_id', data.user_id)
            }

            var api_url = "/api/friends_list";
            axios
                .post(api_url, form)
                .then((response) => {

                    resolve(response);
                    context.commit("FRIENDS_LIST", response.data.data.data);
                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    reject(error)
                    context.commit("SHOW_LOADER", false);
                });
        });
    },


    getTxnHistory(context, data) {

        return new Promise((resolve, reject) => {

            context.commit("SHOW_LOADER", true);
            var form = new FormData();
            form.append('page', data.options.page);
            form.append('page_size', data.options.itemsPerPage);
            form.append('search', data.search);

            if (data.user_id) {
                form.append('user_id', data.user_id)
            }

            var api_url = "/api/get_txn_history";
            axios
                .post(api_url, form)
                .then((response) => {

                    resolve(response);
                    context.commit("SET_TXN_HISTORY", response.data.data.data);
                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    reject(error)
                    context.commit("SHOW_LOADER", false);
                });
        });
    },






    deleteUser(context, item) {

        var form = new FormData();

        form.append('id', item.id,);
        form.append('type', item.type);

        axios
            .post("/api/admin/delete_user", form)
            .then(response => {

                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Delete Successfully",
                });
                if (item.type == 1) {
                    // context.commit("PRE_REG_USER_DATA", response.data.data.data);
                } else {
                    // context.commit("REG_USER_DATA", response.data.data.data);
                }
            })
            .catch(error => {
                this.snackbar.message = "Delete Failed"
                this.snackbar.color = "red"
                this.snackbar.show = true
            });


    },

    upcomingBirthdaysNew(context, data) {

        context.commit("SHOW_LOADER", true);

        var api_url = "/api/user/upcomingBirthdays";
        const form = new FormData();
        // form.append("user_id", user_id);

        axios
            .post(api_url, form)
            .then((response) => {
                var success_statuses = [200, 201, 202, 203, 204, 205];
                if (success_statuses.includes(response.data.status)) {
                    context.commit("COMMING_BIRTHDAY", response.data.data);
                } else { }
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {

                context.commit("SHOW_LOADER", false);
            });
    },
    set_primary_address(context, data) {
        context.commit("SHOW_LOADER", true);

        var api_url = "/api/set_primary_address";
        const form = new FormData();
        form.append("address_id", data.address_id);
        form.append("isPrimary", data.isPrimary);
        // form.append("user_id", user_id);


        axios
            .post(api_url, form)
            .then((response) => {

                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: response.data.message,
                });

                context.commit("USER_ADDRESS", response.data.data);
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                //(error);
                context.commit("SHOW_LOADER", false);
                var errorMsg = "One address should be primary";
                if (error.response) {
                    errorMsg = error.response.data.message;
                }
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: errorMsg,
                });
            });
    },
    set_primary_number_type(context, data) {

        context.commit("SHOW_LOADER", true);


        var api_url = "/api/set_primary_number_type";
        const form = new FormData();
        form.append("mobile_id", data.mobile_id);
        form.append("isPrimary", data.isPrimary);
        // form.append("user_id", user_id);


        axios
            .post(api_url, form)
            .then((response) => {
                context.commit("SHOW_LOADER", false);
                var success_statuses = [200, 201, 202, 203, 204, 205];
                if (success_statuses.includes(response.data.status)) {
                    context.commit("SHOW_SNACKBAR", {
                        success: true,
                        message: 'Change Successfully',
                    });
                    context.commit("USER_MOBILES", response.data.data);

                } else {
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: response.data.message,
                    });
                }
            })
            .catch((error) => {
                //(error);
                context.commit("SHOW_LOADER", false);
                var errorMsg = "Mobile number is Private number!";
                if (error.response) {
                    errorMsg = error.response.data.message;
                }
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: errorMsg,
                });

            });
    },
    set_private_number_type(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/set_private_number_type";


        const form = new FormData();
        form.append("mobile_id", data.mobile_id);
        form.append("isPrivate", data.isPrivate);

        axios
            .post(api_url, form)
            .then((response) => {
                context.commit("SHOW_LOADER", false);
                var success_statuses = [200, 201, 202, 203, 204, 205];
                if (success_statuses.includes(response.data.status)) {
                    context.commit("SHOW_SNACKBAR", {
                        success: true,
                        message: 'Change Successfully',
                    });
                    context.commit("USER_MOBILES", response.data.data);
                } else {
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: response.data.message,
                    });
                }
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                //(error);
                var errorMsg = "Mobile number is Primary number!";
                if (error.response) {
                    errorMsg = error.response.data.message;
                }
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: errorMsg,
                });

            });
    },
    edit_user_mobile(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("SHOW_LOADER", true);
            var api_url = "/api/web_edit_user_mobile";
            const form = new FormData();
            form.append("mobile_no", data.mobile_no);
            form.append("country_code", data.country_code);
            form.append("mobile_id", data.mobile_id);
            form.append("GCtoken", data.GCtoken)
            // form.append("isPrimary", data.isPrimary);
            // form.append("isPrivate", data.isPrivate);
            // form.append("user_id", user_id);


            axios
                .post(api_url, form)
                .then((response) => {

                    context.commit("USER_MOBILES", response.data.data);
                    resolve(response);
                    context.commit("SHOW_SNACKBAR", {
                        success: true,
                        message: response.data.message,
                    });

                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    reject(error);
                    //(error);
                    var errorMsg = "Something went wrong.";
                    if (error.response.data.message) {
                        errorMsg = error.response.data.message;
                    }
                    context.commit("SHOW_LOADER", false);
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: errorMsg,
                    });
                });
        })
    },
    delete_user_mobile(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/delete_number";
        const form = new FormData();
        form.append("id", data);
        form.append("type", 1);

        axios
            .post(api_url, form)
            .then((response) => {

                context.commit("USER_MOBILES", response.data.data);
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: 'Delete Successfully',
                });

                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                //(error);
                var errorMsg = "Delete Failed!";
                if (error.response.data.message) {
                    errorMsg = error.response.data.message;
                }
                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: errorMsg,
                });
            });
    },

    admin_update_user_password(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("SHOW_LOADER", true);
            var api_url = "/api/admin/admin_update_user_password";
            const form = new FormData();
            form.append("email", data.email);
            form.append("password", data.new_password);

            axios
                .post(api_url, form)
                .then((response) => {
                    resolve(true);
                    context.commit("SHOW_SNACKBAR", {
                        success: true,
                        message: "Password updated successfully",
                    });
                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    context.commit("SHOW_LOADER", false);
                    reject(false);
                    var errorMsg = "Something went wrong!";
                    if (error.response) {
                        errorMsg = error.response.data.message;
                    }
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: errorMsg,
                    });

                });
        })
    },
    add_mobile_number(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("SHOW_LOADER", true);
            var api_url = "/api/web_add_mobile_number";
            const form = new FormData();
            form.append("mobile_no", data.mobile_no);
            form.append("isPrimary", data.isPrimary);
            form.append("isPrivate", data.isPrivate);
            form.append("country_code", data.country_code);
            form.append("GCtoken", data.GCtoken);
            // form.append("user_id", user_id);

            axios
                .post(api_url, form)
                .then((response) => {
                    var success_statuses = [200, 201, 202, 203, 204, 205];
                    if (success_statuses.includes(response.data.status)) {
                        resolve(true);
                        context.commit("SHOW_SNACKBAR", {
                            success: true,
                            message: "Create successfully.",
                        });
                    } else {
                        context.commit("SHOW_SNACKBAR", {
                            success: false,
                            message: response.data.message,
                        });
                    }
                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    context.commit("SHOW_LOADER", false);
                    reject(false);
                    var errorMsg = "Mobile Number Already Exist !";
                    if (error.response) {
                        errorMsg = error.response.data.message;
                    }
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: errorMsg,
                    });

                });
        })
    },

    getUserMobileNumber(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/get_mobile_numbers";
        const form = new FormData();

        axios
            .post(api_url, form)
            .then((response) => {
                var success_statuses = [200, 201, 202, 203, 204, 205];
                if (success_statuses.includes(response.data.status)) {
                    context.commit("USER_MOBILES", response.data.data);
                }
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },
    getUserAddress(context, data) {

        context.commit("SHOW_LOADER", true);
        const form = new FormData();
        form.append("type", 1);
        if (data.info_id) {
            form.append("user_id", data.info_id);
        }
        var api_url = "/api/get_all_addresses";

        axios
            .post(api_url, form)
            .then((response) => {
                context.commit("SHOW_LOADER", false);
                context.commit("USER_ADDRESS", response.data.data);
            })
            .catch((error) => {

                context.commit("SHOW_LOADER", false);
            });

    },
    update_user_address(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("SHOW_LOADER", true);

            var api_url = "/api/web_edit_address";
            const form = new FormData();
            form.append("city_name", data.city_name);
            form.append("zipcode", data.zipcode);
            form.append("address1", data.address1);
            form.append("address2", data.address2);
            form.append("address_type", data.address_type);
            form.append("house_flat_number", data.house_flat_number);
            form.append("country_name", data.country_name);
            form.append("address_id", data.address_id);
            // form.append("user_id", user_id);
            form.append("GCtoken", data.GCtoken);
            form.append("type", 'web');

            axios
                .post(api_url, form)
                .then((response) => {
                    var success_statuses = [200, 201, 202, 203, 204, 205];
                    if (success_statuses.includes(response.data.status)) {
                        resolve(true);
                        context.commit("SHOW_SNACKBAR", {
                            success: true,
                            message: "Address updated successfully.",
                        });
                    } else {
                        context.commit("SHOW_SNACKBAR", {
                            success: false,
                            message: response.data.message,
                        });
                    }
                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    resolve(false);
                    //(error);
                    var errorMsg = "Something went wrong.";
                    if (error.response.data.message) {
                        errorMsg = error.response.data.message;
                    }
                    context.commit("SHOW_LOADER", false);
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: errorMsg,
                    });
                });
        })
    },
    delete_user_address(context, data) {
        context.commit("SHOW_LOADER", true);



        var api_url = "/api/delete_address";
        const form = new FormData();
        form.append("address_id", data);

        axios
            .post(api_url, form)
            .then((response) => {
                var success_statuses = [200, 201, 202, 203, 204, 205];
                if (success_statuses.includes(response.data.status)) {
                    context.commit("SHOW_SNACKBAR", {
                        success: true,
                        message: response.data.message,
                    });
                } else {
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: response.data.message,
                    });
                }
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                //(error);
                var errorMsg = "Something went wrong.";
                if (error.response.data.message) {
                    errorMsg = error.response.data.message;
                }
                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: errorMsg,
                });
            });
    },
    addUserAddress(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("SHOW_LOADER", true);


            var api_url = "/api/web_addUserAddress";
            const form = new FormData();
            form.append("city_name", data.city_name);
            form.append("zipcode", data.zipcode);
            form.append("address1", data.address1);
            form.append("address2", data.address2);
            form.append("address_type", data.address_type);
            form.append("country_name", data.country_name);
            form.append("house_flat_number", data.house_flat_number);
            // form.append("user_id", user_id);
            form.append("GCtoken", data.GCtoken);

            axios
                .post(api_url, form)
                .then((response) => {
                    var success_statuses = [200, 201, 202, 203, 204, 205];
                    if (success_statuses.includes(response.data.status)) {
                        resolve(true);
                        context.commit("SHOW_SNACKBAR", {
                            success: true,
                            message: "Create Successfully",
                        });
                    } else {
                        context.commit("SHOW_SNACKBAR", {
                            success: false,
                            message: response.data.message,
                        });
                    }
                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    reject(false);
                    var errorMsg = "Create Failed!";
                    if (error.response.data.message) {
                        errorMsg = error.response.data.message;
                    }
                    context.commit("SHOW_LOADER", false);
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: errorMsg,
                    });
                });
        })
    },
};

const mutations = {
    //USER_MOBILES_DATA
    USER_MOBILES_DATA(state, data) {
        state.users_mobiles = data;
    },
    USER_ADDRESS_TYPE_DATA(state, data) {
        state.addressType = data;
    },
    REG_USER_DATA(state, data) {
        state.registeredUser = data;
    },

    REG_LOGS(state, data) {
        state.register_logs = data;
    },
    REMINDER_LOGS(state, data) {
        state.reminder_logs = data;
    },

    FRIENDS_LIST(state, data) {
        state.friends_list = data;
    },
    SET_TXN_HISTORY(state, data) {
        state.txn_history = data;
    },

    PRE_REG_USER_DATA(state, data) {
        state.preRegisterUsers = data;
    },
    SHOW_LOADER(state, status) {
        state.loaderstate = status;
    },
    SIGNUP_STATUS(state, signupState) {
        state.signupState = signupState;
    },

    USER_ADDRESS(state, userAddress) {

        state.userAddress = userAddress;
    },

    COMMING_BIRTHDAY(state, users_birthday) {
        state.users_birthday = users_birthday;
    },
    USER_MOBILES(state, userMobileNumber) {
        state.userMobileNumber = userMobileNumber;
    },

    SHOW_LOADER(state, status) {
        state.loaderstate = status;
    },

    SET_USER_ID(state, data) {
        state.user_id = data.user_id;
    },

    SHOW_SNACKBAR(state, payload) {
        state.showSnackBar.show = true;
        state.showSnackBar.message = payload.message;
        var color = "red";
        if (payload.success) {
            color = "primary";
        }

        state.showSnackBar.color = color;
    },
};
export default {
    state,
    getters,
    actions,
    mutations,
};