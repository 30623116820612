import axios from "axios";

const state = {
    generaldata: {},
    bannerSection: [],
    registatus: false,
    loaderstate: false,
    status: false,
    showSnackBar: {
        show: false,
        message: "",
        color: ""
    },
};

const getters = {};

const actions = {

    preRegUsers(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/preRegUsers";
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("password", data.password);
        formData.append("type", 1); // from web
        formData.append("GCtoken", data.GCtoken);
        axios
            .post(api_url, formData)
            .then(response => {
                context.commit("SHOW_LOADER", false);
               
                if(response.data) {
                    context.commit("PRE_REGISTER_USER", true);
                    context.commit("SHOW_SNACKBAR", {
                        color: "primary",
                        message: response.data.message
                    });

                } else {
                    context.commit("SHOW_SNACKBAR", {
                        color: "error",
                        message: "Enter Valid Email/Phone !"
                    });

                }

            })
            .catch(error => {
                context.commit("SHOW_LOADER", false);
                var errorMsg = "Registration Failed!"
                if(error.response){
                    errorMsg = error.response.data.message;
                }
              
                context.commit("SHOW_SNACKBAR", {
                    color: "error",
                    message: errorMsg
                });

            });

    },

}

const mutations = {

    //
    BANNER_SECTION_DATA(state, data) {
        state.bannerSection = status;
    },
    PRE_REGISTER_USER(state, status) {
        state.registatus = status;
    },
    SHOW_SNACKBAR(state, payload) {
        state.showSnackBar.show = true;
        state.showSnackBar.message = payload.message;
        state.showSnackBar.color = payload.color;
    },
    SHOW_LOADER(state, status) {
        state.loaderstate = status;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};