<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">Merchant <span>Registration</span></h4>
          <br />
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img
            class="v3-logo"
            :src="'/assets/img/logo.png'"
            alt="Logo"
          />

          <p class="v3-sub-title">One app - all your connections</p>

          <h2>Hi, Welcome!</h2>
          <p class="v3-sub-p">Please sign in below</p>

          <v-row class="zero-flex" justify="center">
            <v-col cols="12" sm="12" md="7" lg="6">
              <v-text-field
                background-color="white"
                v-model="merchant.email"
                placeholder="Enter your email id"
                outlined
                @input="$v.merchant.email.$touch()"
                @blur="$v.merchant.email.$touch()"
                :error-messages="emailErrors"
                class="h50-field"
              ></v-text-field>

              <v-btn
                :loading="loader"
                class="primary-bg-line-btn"
                @click="emailVerify()"
                block
                depressed
                outlined
                large
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>

          <div class="v3-auth-sub-footer">
            <p @click="forgetPassword()" class="text-1 mx-auto">
              Forgotten your password?
            </p>
            <h3 @click="createAccount()" class="text-2 mx-auto">
              Create Account
            </h3>
          </div>
        </v-col>
      </v-row>

      <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
import { mapState } from "vuex";
import MerchantBannerWidget from "../auth/MerchantBannerWidget.vue";

export default {
  components: {
    MerchantBannerWidget,
  },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      merchant: {
        emaill: "",
      },
      loader: false,
      snackbar: {
        show: false,
        message: "",
        color: null,
      },
      riveInstance: null,
    };
  },
  validations: {
    merchant: {
      email: {
        required,
        email,
      },
    },
  },

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.merchant.email.$dirty) return errors;
      !this.$v.merchant.email.email && errors.push("Must be valid e-mail");
      !this.$v.merchant.email.required && errors.push("E-mail is required");
      return errors;
    },
  },

  methods: {
    createAccount() {
      this.$router.push({ name: "merchantRegister" });
    },

    forgetPassword() {
      this.$router.push({ name: "merchantV3ResetPassword" });
    },

    async emailVerify() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.snackbar = {
          message: "Enter Vaild email",
          color: "red",
          show: true,
        };
      } else {
        const form = new FormData();
        form.append("email", this.merchant.email);
        await this.$recaptchaLoaded();
        const GCtoken = await this.$recaptcha("login");
        form.append("GCtoken", GCtoken);
        axios
          .post(
            "/api/v3/email_verify",
            form
            // { email: this.merchant.email }
          )
          .then((resposne) => {
            var res = resposne.data.data;
            this.$router.push({
              name: "merchantPasswordLogin",
              params: { userInfo: res.email },
            });
          })
          .catch((e) => {
            this.snackbar = {
              message: e.response.data.message,
              color: "red",
              show: true,
            };
            this.loader = false;
            this.email_otp_send = false;
            this.errorSnackBar(e.response.data.message);
          });
        return;
      }
    },
  },
};
</script>
