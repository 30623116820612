<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">Merchant <span>Registration</span></h4>
          <br />
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img class="v3-logo" :src="'/assets/img/logo.png'" alt="Logo" />

          <p class="v3-sub-title">One app - all your connections</p>
          <h2 class="mt-5">Almost there</h2>

          <v-row class="zero-flex" justify="center">
            <v-col cols="12" sm="12" md="8" lg="7">
              <div>
                <v-row justify="center">
                  <v-col                                 
                    cols="12"
                    sm="12"
                    :md="searchLoading == true ? '11' : '12'"
                  >
                    <p>Your Address</p>

                    <!-- :filter="filter"  -->
                    <v-autocomplete
                      background-color="white"
                      class="h50-field"
                      placeholder="Start Typing"
                      outlined
                      v-model="merchant.address"
                      :items="post_code_array"
                      item-text="text"
                      item-value="value"
                      :search-input.sync="searchLocation"
                      @keyup="searchRepo()"
                      :error-messages="postCodeErrors"
                      @input="$v.merchant.post_code.$touch()"
                      @blur="$v.merchant.post_code.$touch()"
                      @change="addressClick(merchant.address)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    v-if="searchLoading"
                    class="mt-9"
                    v-col
                    cols="12"
                    sm="12"
                    md="1"
                  >
                    <v-progress-circular
                      size="30"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-col>
                </v-row>

                <p
                  v-if="!enterManually"
                  @click="addressManually()"
                  class="address-manually"
                >
                  Enter address manually
                </p>

                <v-row v-if="enterManually" justify="center">
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      dense
                      placeholder="Address Line 1"
                      outlined
                      required
                      v-model="manuallyAddress.address_line_1"
                      :error-messages="addressLine1Errors"
                      @input="$v.manuallyAddress.address_line_1.$touch()"
                      @blur="$v.manuallyAddress.address_line_1.$touch()"
                      class="h50-field"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      dense
                      placeholder="Address Line 2"
                      outlined
                      required
                      class="h50-field"
                      v-model="manuallyAddress.address_line_2"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      dense
                      placeholder="City"
                      outlined
                      required
                      class="h50-field"
                      v-model="manuallyAddress.city"
                      :error-messages="cityErrors"
                      @input="$v.manuallyAddress.city.$touch()"
                      @blur="$v.manuallyAddress.city.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-select
                      placeholder="United Kingdom"
                      :items="country_codes"
                      background-color="white"
                      required
                      dense
                      outlined
                      class="h50-field"
                      v-model="manuallyAddress.country"
                    ></v-select>
                      <!-- :error-messages="countryErrors"
                      @input="$v.manuallyAddress.country.$touch()"
                      @blur="$v.manuallyAddress.country.$touch()" -->
                  </v-col>
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field
                      background-color="white"
                      dense
                      placeholder="PostCode/ZipCode"
                      outlined
                      required
                      class="h50-field"
                      v-model="manuallyAddress.post_code"
                      :error-messages="manuallyPostCodeErrors"
                      @input="$v.manuallyAddress.post_code.$touch()"
                      @blur="$v.manuallyAddress.post_code.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <br />
                <div>
                  <v-btn
                    :loading="saveLoading"
                    class="primary-bg-line-btn mt-3"
                    outlined
                    block
                    large
                    @click="merchantAddress()"
                  >
                    Continue
                  </v-btn>
                </div>
              </div>
              <br />
              <br />
              <div class="merchant-step-area">
                <p>2/4</p>
                <img
                  :src="'/assets/v3_assets/custom_shapes/m_step_2.png'"
                  alt="Logo"
                />
              </div>

              <!-- <div @click="goBack()" class="auth-back-btn i">
                <v-btn class="v3-div-clr" elevation="0" depressed flex>
                  <img
                    class="v3-back-btn"
                    :src="'/assets/v3_assets/back_btn.svg'"
                  />
                  Back
                </v-btn>
              </div> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import axios from "axios";
import MerchantBannerWidget from "./MerchantBannerWidget.vue";

export default {
  props: ["userInfo"],

  components: {
    MerchantBannerWidget,
  },

  data: () => ({
    loading: false,
    // filter: false,
    saveLoading: false,
    searchLocation: null,
    searchLoading: false,
    enterManually: false,
    arrayOfAddress: [],
    post_code_array: [],
    selectedAddress: null,
    merchant: {
      address: "",
      city: "",
      post_code: "",
      country: 1,
    },
    snackbar: {
      show: false,
      message: "",
      color: null,
    },

    manuallyAddress: {
      address_line_1: "",
      address_line_2: "",
      city: "",
      country: "",
      post_code: "",
    },
    country_codes: [
      {
        text: "United Kingdom",
        value: 1,
      },
      {
        text: "India",
        value: 2,
      },
    ],
  }),

  validations: {
    merchant: {
      post_code: { required },
      address: { required },
    },

    manuallyAddress: {
      address_line_1: {
        required: requiredIf(function (abc) {
          return this.enterManually=true;
        }),
      },

      city: {
        required: requiredIf(function (abc) {
          return this.enterManually=true;
        }),
      },
      post_code: {
        required: requiredIf(function (abc) {
          return this.enterManually=true;
        }),
      },
    },
  },

  computed: {
    ...mapState({
      codeAddress: (state) => state.user_login.codeAddress,
      countries: (state) => state.custom.countries,
    }),
    postCodeErrors() {
      const errors = [];
      if (!this.$v.merchant.address.$dirty) return errors;
      !this.$v.merchant.address.required &&
        errors.push("Post code is required.");
      return errors;
    },

    addressLine1Errors() {
      const errors = [];
      if (!this.$v.manuallyAddress.address_line_1.$dirty) return errors;
      !this.$v.manuallyAddress.address_line_1.required &&
        errors.push("Address line 1 is required");
      return errors;
    },

    cityErrors() {
      const errors = [];
      if (!this.$v.manuallyAddress.city.$dirty) return errors;
      !this.$v.manuallyAddress.city.required && errors.push("City is required");
      return errors;
    },

    // countryErrors() {
    //   const errors = [];
    //   if (!this.$v.manuallyAddress.country.$dirty) return errors;
    //   !this.$v.manuallyAddress.country.required &&
    //     errors.push("Country is required");
    //   return errors;
    // },

    manuallyPostCodeErrors() {
      const errors = [];
      if (!this.$v.manuallyAddress.post_code.$dirty) return errors;
      !this.$v.manuallyAddress.post_code.required &&
        errors.push("Postcode is required");
      return errors;
    },

    addressType: function () {
      return this.$store.state.user_module.addressType.map((e) => {
        return {
          text: e.address,
          value: e.id,
        };
      });
    },
  },

  watch: {
    codeAddress(addresses) {
      console.log(addresses);
      if (addresses) {
        this.$printLog("=====address1====>", addresses);
        this.showAddField = true;
        this.merchant.address = addresses.address;
        this.merchant.city = addresses.city;
        // this.merchant.country = addresses.country;
      } else {
        this.snackbar.show = true;
        this.snackbar.color = "error";
        this.snackbar.message = "Address not found please try again";
      }
    },
  },
  created() {
    if (this.userInfo) {
      var item = this.userInfo;
      console.log(this.userInfo);
      if (item.post_code) {
        this.merchant.post_code = item.post_code;
        this.post_code_array.push(item.post_code);
        this.manuallyAddress.address_line_1 = item.address;
        this.manuallyAddress.address_line_2 = item.address_line2;
        this.manuallyAddress.city = item.city;
        this.manuallyAddress.post_code = item.post_code;
        this.manuallyAddress.country = 1;
        this.enterManually = true;
      }
    } else {
      this.$router.replace("/v3/merchant/signup");
    }
    this.$store.dispatch("getCountries", {});
  },
  methods: {
    addressManually() {
      this.enterManually = true;
    },

    goBack() {
      this.$router.push({
        name: "merchantRegisterYourDetail",
        params: { userInfo: this.userInfo },
      });
    },

    homeDetail() {
      // this.$router.push({ name: "merchantRegisterYourCompany" });
      // return;
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.validate = false;
      } else {
        // alert("else")
        this.validate = this.merchant;
      }
    },
    searchRepo: _.debounce(function () {
      this.searchLoading = true;
      this.autofillData();
    }, 300),
    async autofillData() {
      this.post_code_array = [];

      if (this.searchLocation <= 1) {
        this.searchLoading = false;
        return this.$store.dispatch(
          "globalState/errorSnackBar",
          "Please fill valid pin code."
        );
      }
      const form = new FormData();
      form.append("selected_post_code", this.searchLocation);
      await this.$recaptchaLoaded();
      const GCtoken = await this.$recaptcha("login");
      form.append("GCtoken", GCtoken);

      axios.post("/api/v3/get_web_address", form).then((response) => {
        if (response.data.data && response.data.data.suggestions) {
          if (response.data.data.suggestions.length < 1) {
            this.searchLoading = false;
            this.post_code_array = [];
            this.$store.dispatch(
              "globalState/successSnackBar",
              "Address not found."
            );
          } else {
            this.searchLoading = false;
            this.post_code_array = response.data.data.suggestions.map(
              (item) => {
                return {
                  text: item.address,
                  value: item.id,
                };
              }
            );

            this.$store.dispatch(
              "globalState/successSnackBar",
              "Please select address from list"
            );
          }
        } else {
          this.searchLoading = false;
          this.post_code_array = [];
          this.$store.dispatch(
            "globalState/successSnackBar",
            "Address not found."
          );
        }
      });
    },

    addressClick(id) {
      this.getFullAddress(id);
    },

    async getFullAddress(address_id) {
      const form = new FormData();
      form.append("address_id", address_id);
      axios
        .post("/api/v3/selectedUserData", form)
        .then((response) => {
          var address = response.data.data;

          this.manuallyAddress.address_line_1 = address.line_1;
          this.manuallyAddress.address_line_2 =
            address.line_2 + ", " + address.line_3;
          this.manuallyAddress.city = address.town_or_city;
          this.manuallyAddress.post_code = address.postcode;
          this.manuallyAddress.country = 1;
          this.enterManually = true;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    errorSnackBar(msg) {
      this.snackbar.show = true;
      this.snackbar.color = "error";
      this.snackbar.message = msg;
    },

    async merchantAddress() {
      if (  this.$v.manuallyAddress.address_line_1.$invalid ||
    this.$v.manuallyAddress.city.$invalid ||
    this.$v.manuallyAddress.post_code.$invalid) {
        this.$v.$touch();

        this.snackbar = {
          message: "Please check all form fields",
          color: "red",
          show: true,
        };
      } else {
      const form = new FormData();

      form.append("address_id", this.merchant.address);
      form.append("merchant_id", this.userInfo.id);
      form.append("section", "address");

      if (this.enterManually) {
        form.append("address_line_1", this.manuallyAddress.address_line_1);
        form.append("address_line_2", this.manuallyAddress.address_line_2);
        form.append("city", this.manuallyAddress.city);
        form.append("country", this.manuallyAddress.country);
        form.append("post_code", this.manuallyAddress.post_code);
      }

      await this.$recaptchaLoaded();
      const GCtoken = await this.$recaptcha("login");
      form.append("GCtoken", GCtoken);
      this.saveLoading = true;
      axios
        .post("/api/v3/merchant_register", form)
        .then((response) => {
          this.saveLoading = false;
          this.$router.push({
            name: "merchantRegisterYourCompany",
            params: { userInfo: response.data.data },
          });
        })
        .catch((e) => {
          this.saveLoading = false;
           this.snackbar = {
              message: e.response.data.message,
              color: "red",
              show: true,
            };
             this.$router.replace("/v3/merchant/signup");
        });
    }
    },
  },
};
</script>

<style scoped>
.v-stepper__header {
  box-shadow: none !important;
}
</style>
