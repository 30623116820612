// FORM BUILDER
const USER = 0;
const MERCHANT = 1;
const GENDERS = [

    { value: '0', text: "Male" },
    { value: '1', text: "Female" },
    { value: '2', text: "Non-binary" },
    { value: '3', text: "Transgender" },
    { value: '4', text: "Intersex" },
    { value: '5', text: "I prefer not to say" },

];

const EVENT_MESSAGES = [
    { EVENT_1_MESSAGE: "User clicked on Dustid Button" },
    { EVENT_2_MESSAGE: "Phone number Submitted and Otp Sent" },
    { EVENT_3_MESSAGE: "Otp Autosent from session cookies" },
    { EVENT_4_MESSAGE: "Phone Number dosn't exist (Signup / Enter Another Number)" },
    { EVENT_5_MESSAGE: "Submitted Otp, Get Friends List" },
    { EVENT_6_MESSAGE: "Select User Friend and Get Address" },
    { EVENT_7_MESSAGE: "Click to Place Order" },
    { EVENT_8_MESSAGE: "Click to Dustid Link" },
    { EVENT_9_MESSAGE: "Click to Signup button" },
    { EVENT_10_MESSAGE: "Get Otp" },
    { EVENT_11_MESSAGE: "Fetch Address" },
    { EVENT_12_MESSAGE: "Click to Register Button" },
    { EVENT_13_MESSAGE: "Resend Otp" },
    { EVENT_14_MESSAGE: "Check Checkbox Quick Signup With DustiD" },
    { EVENT_15_MESSAGE: "Cilck to Place Order" },
    { EVENT_16_MESSAGE: "Merchant Generated Live Token " },
    { EVENT_17_MESSAGE: "Merchant Installed the Live Token on their Website" },
    { EVENT_18_MESSAGE: "Merchant Generated Test Token" },
    { EVENT_19_MESSAGE: "Merchant Installed the Test Token on their Website" },
    { EVENT_20_MESSAGE: "Merchant Fetched Address from Dustid Database for an Order"},
    { EVENT_21_MESSAGE: "Phone number Submitted and Generate Biometric Auth"},
    { EVENT_22_MESSAGE: "Biometric Auth auto generate from session cookies"},
    { EVENT_23_MESSAGE: "Biometric Auth approved, Get Friends List"},
    { EVENT_24_MESSAGE: "Select User Friend and Get Address"},
    { EVENT_25_MESSAGE: "Cilcked to Place Order"},
];
const AUTHENTICATION_OTP_FROM = process.env.MIX_AUTHENTICATION_OTP_FROM;
const FIREBASE = 1;
const SERVERSIDE = 2;

export default {
    USER: USER,
    MERCHANT: MERCHANT,
    GENDERS: GENDERS,
    EVENT_MESSAGES: EVENT_MESSAGES,
    AUTHENTICATION_OTP_FROM: AUTHENTICATION_OTP_FROM,
    FIREBASE: FIREBASE,
    SERVERSIDE: SERVERSIDE,
};
