import axios from "axios";
import Cookies from 'js-cookie'
import { reject } from "lodash";
const state = {
    loaderstate: false,
    codeAddress: {},
    password_changed: false,
    getMobile: false,
    getUserprofile: Object,
    userAddress: Object,
    loginState: false,
    signupState: false,
    showSnackBar: {
        show: false,
        message: "",
        color: "",
    },
    hasError: false,
    errorMessage: "",
    mobile_verify_user: {},
};

const getters = {};

const actions = {
    addressByPostCode(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/places?zip=" + data;

        // return
        // const form = new FormData();
        // form.append("user_id", data);
        axios
            .get(api_url)
            .then((response) => {
                // Vue.prototype.$printLog("====>post code >" + response.data);
                context.commit("ADDRESS_BY_CODE", response.data);

                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });

    },


    userForgotPass(context, data) {
        return new Promise((resolve, reject) => {

            context.commit("SHOW_LOADER", true);
            context.commit("GET_MOBILE", false);

            var api_url = "/api/userForgotPass";
            const form = new FormData();
            form.append("password", data.password);
            form.append("mobile_no", data.mobile_no);
            form.append("GCtoken", data.GCtoken);
            form.append('token', data.token);
            axios
                .post(api_url, form)
                .then((response) => {
                    context.commit("SHOW_LOADER", false);

                    var success_statuses = [200, 201, 202, 203, 204, 205];
                    if (success_statuses.includes(response.data.status)) {
                        resolve(response)
                        context.commit("PASSWORD_CHANGED", true);
                        context.commit("SHOW_SNACKBAR", {
                            success: true,
                            message: response.data.message,
                        });
                    } else {
                        var errorMessage = response.data.mobile_no ? response.data.mobile_no[0] : "Password Required";
                        context.commit("SHOW_SNACKBAR", {
                            success: false,
                            message: errorMessage,
                        });
                    }

                })
                .catch((error) => {
                    context.commit("SHOW_LOADER", false);
                    reject(error)
                    var errorMsg = "Something went wrong.";
                    if (error.response) {
                        errorMsg = error.response.data.message;
                    }

                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: errorMsg,
                    });
                });
        });
    },

    check_phone_number(context, item) {

        return new Promise((resolve, reject) => {

            context.commit("SHOW_LOADER", true);

            var api_url = "/api/check_phone_number";
            var fd = new FormData();
            fd.append("mobile_no", item.mobile_no);
            fd.append("mobile_no", item.mobile_no);
            fd.append("GCtoken", item.GCtoken)

            axios
                .post(api_url, fd)
                .then((response) => {
                    resolve(response)
                    if (response.data) {
                        context.commit("GET_MOBILE", true);
                    } else {
                        context.commit("SHOW_SNACKBAR", {
                            success: false,
                            message: "Enter your Valid Number!",
                        });
                        context.commit("GET_MOBILE", false);
                    }
                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {

                    reject(error)

                    var errorMsg = "Something went wrong!";
                    if (error.response) {
                        errorMsg = error.response.data.message;

                    }

                    context.commit("GET_MOBILE", false);
                    context.commit("SHOW_LOADER", false);
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: errorMsg,
                    });

                })
        });
    },

    async get_forget_pass_number(context, data) {
        context.commit("SHOW_LOADER", true);

        var api_url = "/api/user/get_forget_pass_number";
        var fd = new FormData();
        fd.append("mobile_no", data);


        try {
            var response = await axios
                .post(api_url, fd)
            var success_statuses = [200, 201, 202, 203, 204, 205];
            context.commit("SHOW_LOADER", false);
            if (success_statuses.includes(response.data.status)) {
                context.commit("GET_MOBILE", true);
                return true;
            } else {
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: response.data.message,
                });
                context.commit("GET_MOBILE", false);
                return false;
            }
        } catch (error) {
            var errorMsg = "Something went wrong.";
            if (error.response.data.message) {
                errorMsg = error.response.data.message;
            }
            context.commit("GET_MOBILE", false);
            context.commit("SHOW_LOADER", false);
            context.commit("SHOW_SNACKBAR", {
                success: false,
                message: errorMsg,
            });
            return false;
        }


    },
    change_user_password(context, data) {

        return new Promise((resolve, reject) => {

            context.commit("SHOW_LOADER", true);
            var api_url = "/api/web_change_user_password";
            const form = new FormData();
            form.append("oldpassword", data.oldPass);
            form.append("password", data.newPass);
            form.append("confirm_password", data.confirmPass);
            // form.append("user_id", user_id);
            form.append("GCtoken", data.GCtoken)

            axios
                .post(api_url, form)
                .then((response) => {
                    resolve(response)
                    context.commit("SHOW_SNACKBAR", {
                        success: true,
                        message: "Password changed successfully.",
                    });

                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    reject(error)
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: error.response ? error.response.data.message : "Password not update",
                    });
                    context.commit("SHOW_LOADER", false);
                });
        });
    },
    signupUser(context, data) {

        context.commit("SHOW_LOADER", true);
        return new Promise((resolve, reject) => {
        var api_url = "/api/create_account";
        const form = new FormData();
        // data.type = 'web'; // from web
        data.deviceType = 2; // WEB
        axios
            .post(api_url, data)
            .then((response) => {
                context.commit("SHOW_LOADER", false);
                // Vue.prototype.$printLog("==================signupUser ===>" + response.data.data);
                if (response.data) {
                    context.commit("SIGNUP_STATUS", true);
                    context.commit("SHOW_SNACKBAR", {
                        success: true,
                        message: response.data.message,
                    });
                    resolve(response)
                } else {
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: response.data.message,
                    });
                }
            })
            .catch((error) => {
                //(error);
                context.commit("SHOW_LOADER", false);

                var errorMsg = "Something went wrong.";
                if (error.response) {
                    errorMsg = error.response.data.message;
                }
                // Vue.prototype.$printLog("signup errror " + errorMsg);

                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: errorMsg,
                });
            });
        })
    },

    verify_mobile(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/verify_mobile";

        axios
            .post(api_url, data)
            .then((response) => {

                context.commit("USER_UPDATE_PROFILE", response.data.data);
                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: response.data.message,
                });

            })
            .catch((error) => {

                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: error.message,
                });
            });
    },

    updateProfileImage(context, data) {

        return new Promise((resolve, reject) => {


            context.commit("SHOW_LOADER", true);
            var api_url = "/api/web_updateProfileImage";
            const form = new FormData();

            form.append("profile_pic_name", data.profile_pic_name);
            form.append("GCtoken", data.GCtoken);


            axios
                .post(api_url, form)
                .then((response) => {
                    var success_statuses = [200, 201, 202, 203, 204, 205];
                    if (success_statuses.includes(response.data.status)) {
                        context.commit("SHOW_SNACKBAR", {
                            success: true,
                            message: "Profile image  update successfully. ",
                        });

                        resolve(response);

                    } else {
                        context.commit("SHOW_SNACKBAR", {
                            success: false,
                            message: response.data.message,
                        });
                    }

                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    //(error);


                    var message = "Updation Failed.";

                    context.commit("SHOW_LOADER", false);
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: message,
                    });

                    reject(error);
                });

        });
    },
    updateUserProfile(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/web_update_profile";
        const form = new FormData();
        form.append("firstname", data.first_name);
        form.append("lastname", data.last_name);
        form.append("email_id", data.email_id);
        form.append("date_of_birth", data.date_of_birth ?? '');
        form.append("gender", data.gender);
        form.append("GCtoken", data.GCtoken);
        form.append("type", "web");
        form.append("auth_type", data.auth_type);
        axios
            .post(api_url, form)
            .then((response) => {
                var success_statuses = [200, 201, 202, 203, 204, 205];
                if (success_statuses.includes(response.data.status)) {
                    context.commit("SHOW_SNACKBAR", {
                        success: true,
                        message: "Update successfully. ",
                    });
                    // Vue.prototype.$printLog("===============>RESPONSE" + response.data);

                } else {
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: response.data.message,
                    });
                }
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                // Vue.prototype.$printLog("===============>CAtch Block" + error);
                var message = "Updation Failed.";
                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: message,
                });
            });
    },
    getUserProfile(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/get_profile";
        const form = new FormData();
        axios
            .post(api_url, form)
            .then((response) => {
                context.commit("USER_UPDATE_PROFILE", response.data.data);
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },

    loginUser(context, data) {
        return new Promise((resolve, reject) => {

            context.commit("SIGNUP_STATUS", false);
            context.commit("SHOW_LOADER", true);

            var api_url = "/api/user_login";
            const form = new FormData();

            form.append("country_code", data.country_code);
            form.append("password", data.password);
            form.append("mobile_number", data.phone);
            form.append("GCtoken", data.GCtoken);
            form.append("type", "web");
            form.append("device_detail", data.device_detail);

            axios
                .post(api_url, form)
                .then((response) => {
                    resolve(response)
                    if (response.data.status == 200) {
                        // Vue.prototype.$printLog('==========================>RESRRS' ,  JSON.stringify(response.data.user_data));
                        let token = 'Bearer ' + response.data.user_data.access_token;
                        axios.defaults.headers.common['Authorization'] = token;
                        var remember = true;
                        var now = new Date();
                        now.setTime(now.getTime() + 1 * 3600 * 1000);
                        Cookies.set('access_token', token, { expires: remember ? 365 : 1 });

                        context.commit("USER_LOGIN_STATUS", true);
                        context.commit("SHOW_SNACKBAR", {
                            success: true,
                            message: "Login successfully.",
                        });
                    }
                    else {
                        context.commit("USER_LOGIN_STATUS", false);
                        // Vue.prototype.$printLog('========else==================>RESRRS'+response.data);
                        context.commit("SHOW_SNACKBAR", {
                            success: false,
                            message: response.data.message,
                        });

                    }

                    context.commit("SHOW_LOADER", false);
                })
                .catch((error) => {
                    reject(error);
                    //(error);
                    //Vue.prototype.$printLog("========> catchcatch   =>" + error);
                    var errorMsg = "Something went wrong.";
                    context.commit("SHOW_LOADER", false);
                    context.commit("SHOW_SNACKBAR", {
                        success: false,
                        message: error.response ? error.response.data.message : errorMsg
                    });


                });

        })
    },


};
const mutations = {
    //codeAddress

    ADDRESS_BY_CODE(state, data) {
        state.codeAddress = data;
    },
    PASSWORD_CHANGED(state, status) {
        state.password_changed = status;
    },
    GET_MOBILE(state, status) {
        state.getMobile = status;
    },
    SHOW_LOADER(state, status) {
        state.loaderstate = status;
    },
    SIGNUP_STATUS(state, signupState) {
        state.signupState = signupState;
    },
    USER_LOGIN_STATUS(state, loginState) {

        // alert("U"+loginState)
        state.loginState = loginState;
    },
    USER_UPDATE_PROFILE(state, getUserprofile) {
        state.getUserprofile = getUserprofile;
    },
    SHOW_SNACKBAR(state, payload) {
        state.showSnackBar.show = true;
        state.showSnackBar.message = payload.message;
        var color = "red";
        if (payload.success) {
            color = "primary";
        }
        state.showSnackBar.color = color;
    },
    HIDE_SNACKBAR(state, status) {
        state.showSnackBar.show = status;
    },

    MOBILE_VERIFY_USER(state, user) {
        state.mobile_verify_user = user;
    },

};
export default {
    state,
    getters,
    actions,
    mutations,
};