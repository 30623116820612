import axios from "axios";
import { Alert } from "bootstrap";
import { reject } from "lodash";

const state = {
    loaderstate: false,
    message_list: [],
    showSnackBar: {
        show: false,
        message: "",
        color: "",
    }

};

const getters = {};

const actions = {

    get_all_message(context, data) {
        
        return new Promise((resolve , reject) => {

        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/get_all_message";
        var form = new FormData();
        form.append('page', data.options.page);
        form.append('page_size', data.options.itemsPerPage);
        form.append('search', data.search);
        axios
            .post(api_url, form)
            .then(response => {
                resolve(response)
                context.commit("SHOW_LOADER", false);
                //Vue.prototype.$printLog("==================add_contact_us_message.  ===>", response.data);
                context.commit("SET_MESSAGES_LIST", response.data.data.data);
               
            })
            .catch(error => {
               reject(error)
                //(error);
                context.commit("SHOW_LOADER", false);
            });
        });
    },
    add_contact_us_message(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/add_contact_us_message";
        axios
            .post(api_url, data)
            .then(response => {
                context.commit("SHOW_LOADER", false);
                // Vue.prototype.$printLog("==================add_contact_us_message.  ===>", response.data);
                
                if (response.data.status == '200') {
                    context.commit("SHOW_SNACKBAR", {
                        color: "primary",
                        message: "Message Sent Successfully"
                    });

                }
              

            })
            .catch(error => {

                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    color: "red",
                    message: "Message not Sent"
                });
              
            });
    },


}




const mutations = {

    SHOW_LOADER(state, status) {
        state.loaderstate = status;
    },
    
    SHOW_SNACKBAR(state, payload) {
        state.showSnackBar.show = true;
        state.showSnackBar.message = payload.message;
        state.showSnackBar.color = payload.color;
    },
    SET_MESSAGES_LIST(state, data) {
        state.message_list = data;
    },



};

export default {
    state,
    getters,
    actions,
    mutations,
};