import axios from "axios";

const state = {

};

const getters = {};

const actions = {
    get_analytics(context, item) {

        return new Promise((resolve, reject) => {
            var form = new FormData();

            if (item.date_from) {
                form.append('date_from', item.date_from)
                form.append('date_to', item.date_to)
            }
            if (item.merchant_id) {
                form.append('merchant_id', item.merchant_id);
            }
            if (item.website) {
                form.append('website', item.website);
            }
            var api_url = "/api/admin/get_analytics";
            axios
                .post(api_url, form)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                });
        })

    },

    get_events(context, item) {

        return new Promise((resolve, reject) => {
            var form = new FormData();
            form.append('txn_type', item.txn_type);
            form.append('page', item.options.page);
            form.append('search', item.search);
            form.append('page_size', item.options.itemsPerPage);

            if (item.date_from) {
                form.append('date_from', item.date_from)
                form.append('date_to', item.date_to)
            }
            if (item.merchant_id) {
                form.append('merchant_id', item.merchant_id);
            }
            if (item.website) {
                form.append('website', item.website);
            }
            var api_url = "/api/admin/get_events";
            axios
                .post(api_url, form)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                });
        })

    },
    get_merchants(context, search) {

        return new Promise((resolve, reject) => {
            var api_url = "/api/admin/get_merchants";
            var form = new FormData;
            form.append('search', search)
            axios
                .post(api_url, form)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                });
        })

    },

    get_merchant_by_id(context, merchant_id) {

        return new Promise((resolve, reject) => {
            var api_url = "/api/admin/get_merchant_by_id";
            var form = new FormData;
            form.append('merchant_id', merchant_id)
            axios
                .post(api_url, form)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                });
        })

    },

    get_dropdown_websites_by_merchant(context, merchant_id) {

        return new Promise((resolve, reject) => {
            var api_url = "/api/admin/get_dropdown_websites_by_merchant";
            var form = new FormData;
            form.append('merchant_id', merchant_id)
            axios
                .post(api_url, form)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                });
        })

    },



}

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations,
};