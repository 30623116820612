<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">Merchant <span>Sign In</span></h4>
          <br />
          <MerchantBannerWidget />
        </v-col>
        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img class="v3-logo" :src="'/assets/img/logo.png'" alt="Logo" />
          <p class="v3-sub-title">One app - all your connections</p>

          <h2 class="v3-title-text">Hi, Welcome!</h2>

          <p class="v3-sub-p">Your email id has been verified.</p>

          <v-row class="zero-flex" justify="center">
            <v-col cols="12" sm="12" md="7" lg="6">
              <v-text-field
                background-color="white"
                v-model="merchant.email"
                class="h50-field"
                outlined
                :error-messages="emailErrors"
                @input="$v.merchant.email.$touch()"
                @blur="$v.merchant.email.$touch()"
                append-icon="mdi-check-circle-outline"
                required
              ></v-text-field>

              <v-text-field
                background-color="white"
                v-model="merchant.password"
                class="h50-field"
                outlined
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                placeholder="Enter your password"
                hint="At least 8 characters"
                @input="$v.merchant.password.$touch()"
                @blur="$v.merchant.password.$touch()"
                :error-messages="passwordErrors"
                @click:append="show2 = !show2"
                required
              ></v-text-field>

              <v-btn
                class="green-bg-line-btn"
                outlined
                large
                :loading="loader"
                @click="loginMerchant()"
                block
                depressed
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>

          <div class="v3-auth-sub-footer">
            <p @click="forgetPassword()" class="text-1 mx-auto">
              Forgotten your password?
            </p>
            <h3 @click="createAccount()" class="text-2 mx-auto">
              Create Account
            </h3>
          </div>

          <div @click="goBack()" class="auth-back-btn i">
            <v-btn class="v3-div-clr" elevation="0" depressed flex>
              <img
                class="v3-back-btn"
                :src="'/assets/v3_assets/back_btn.svg'"
              />
              Back
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import { UAParser } from "ua-parser-js";
import axios from "axios";
import { mapState } from "vuex";
import MerchantBannerWidget from "./MerchantBannerWidget.vue";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      loader: false,
      show2: false,
      merchant: {
        email: "",
        password: "",
      },
      snackbar: {
        show: false,
        message: "",
        color: null,
      },
    };
  },
  components: {
    MerchantBannerWidget,
  },
  created() {
    if (this.$route.params.userInfo) {
      this.merchant.email = this.$route.params.userInfo;
    } else {
      this.$router.go(-1);
    }
  },
  computed: {
    ...mapState({
      merchant_loginState: (state) => state.merchant_login.loginState,
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.merchant.email.$dirty) return errors;
      !this.$v.merchant.email.required && errors.push("Email is required.");
      !this.$v.merchant.email.email && errors.push("Email must be valid.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.merchant.password.$dirty) return errors;
      !this.$v.merchant.password.required &&
        errors.push("Password is required.");
      !this.$v.merchant.password.minLength &&
        errors.push("Password must be at least 8 numbers.");
      return errors;
    },
  },
  validations: {
    merchant: {
      email: {
        email,
        required,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  methods: {
    createAccount() {
      this.$router.push({ name: "merchantRegister" });
    },
    forgetPassword() {
      this.$router.push({ name: "merchantV3ResetPassword" });
    },

    async loginMerchant() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.snackbar = {
          message: "Enter valid OTP",
          color: "red",
          show: true,
        };
      } else {
        // this.loader = true;
        var parser = new UAParser();
        var uastring1 = navigator.userAgent;
        parser.setUA(uastring1);
        var result = parser.getResult();
        var result = UAParser(uastring1);
        let arch = result.cpu
          ? result.cpu.architecture
          : result.device
          ? result.device.type
          : "";

        let device_detail = {
          os: result.os.name,
          platform: "browser",
          os_version: result.os.version,
          device_name: result.browser.name,
          device_arch: arch,
          platform_version: result.browser.version,
        };
        // this.device_info = device_detail;
        const form = new FormData();
        form.append("password", this.merchant.password);
        form.append("email_id", this.merchant.email);
        await this.$recaptchaLoaded();
        const GCtoken = await this.$recaptcha("enterPassword");
        form.append("GCtoken", GCtoken);
        form.append("device_detail", JSON.stringify(device_detail));
        axios
          .post("/api/v3/login_merchant", form)
          .then((response) => {
            this.loader = false;
            let token = "Bearer " + response.data.data.token;
            axios.defaults.headers.common["Authorization"] = token;
            var remember = "";
            var now = new Date();
            now.setTime(now.getTime() + 1 * 3600 * 1000);
            Cookies.set("access_token", token, { expires: remember ? now : 1 });
            this.$store.commit("MERCHANT_LOGIN_STATUS", true);
            this.$router.push({ name: "merchantDashboard" });
          })
          .catch((error) => {
            this.snackbar = {
              message: error.response.data.message,
              color: "red",
              show: true,
            };
            console.log(error);
            this.loader = false;
            this.$store.dispatch(
              "globalState/errorSnackBar",
              error.response.data.message
            );
          });
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
