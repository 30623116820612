import axios from "axios";

const state = {
    loaderstate: false,
    showSnackBar: {
        show: false,
        message: "",
        color: "",
    },

    our_partners: [],
}

const getters = {

}
const actions = {
    create_new_partners(context, payloadData) {

        context.commit("SHOW_LOADER", true);

        return new Promise((resolve, reject) => {


            const form = new FormData();
            form.append("partner_image", payloadData.partner_image);
            form.append("title", payloadData.title);
            form.append("link", payloadData.link);
            axios
                .post("/api/admin/create_our_partners", form)
                .then((response) => {

                    resolve(response)
                    context.commit("SHOW_SNACKBAR", {
                        success: true,
                        message: "Create Successfully",
                    });
                    context.commit("SHOW_LOADER", false);

                    // this.our_image = null;
                })
                .catch((error) => {

                    reject(error)

                    context.commit("SHOW_LOADER", false);

                    context.commit("SHOW_SNACKBAR", {
                        success: true,
                        message: "Creation failed..",
                    });
                });

        })
    },



    get_our_partners(context) {

        context.commit("SHOW_LOADER", true);
        var api_url = "/api/get_our_partners";
        axios
            .get(api_url)
            .then(response => {
                var success_statuses = [200, 201, 202, 203, 204, 205];
                if (success_statuses.includes(response.data.status)) {
                    context.commit("OUR_PARTNERS", response.data.data);



                } else { alert("our partners") }
                context.commit("SHOW_LOADER", false);

            })
            .catch(error => {
                //(error);
                //Vue.prototype.$printLog("OUR_PARTNERS=========>"+error)

                context.commit("SHOW_LOADER", false);
            });

    }


}
const mutations = {
    SHOW_LOADER(state, status) {
        state.loaderstate = status;
    },
    OUR_PARTNERS(state, our_partners) {
        state.our_partners = our_partners;
    },
    SHOW_SNACKBAR(state, payload) {
        state.showSnackBar.show = true;
        state.showSnackBar.message = payload.message;
        var color = "red";
        if (payload.success) {
            color = "primary";
        }
        state.showSnackBar.color = color;
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}