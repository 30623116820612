import Vue from "vue";
import axios from "axios";
const state = {
  status: "",
  dashboardDetails: Object,
  loaderstate: false,
};

const getters = {};

const actions = {
  getDashboardDetails(context, dispatchData) {

    context.commit("SHOW_LOADER", true);
    var api_url = "/api/get_dashboard_details";
    const form =new FormData();
    
    axios
  .post(api_url, form)
      .then((response) => {
        context.commit("USER_DASHBOARD_DETAILS", response.data);
        context.commit("SHOW_LOADER", false);
      })
      .catch((err) => {
        context.commit("SHOW_LOADER", false);
      });
  },
};

const mutations = {
 
  SHOW_LOADER(state, status) {
    state.loaderstate = status;
  },

  USER_DASHBOARD_DETAILS(state, dashboardDetails) {
    
    state.dashboardDetails = dashboardDetails;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
