import axios from "axios";

const state = {
  loaderstate: false,
  loginState: false,
  dashboardDetails: Object,
  packagesList: [],
  subPackagesList: [],
};

const getters = {};

const actions = {
  dashBoardDetails(context, data) {
    context.commit("SHOW_LOADER", true);
    var api_url = "/api/merchant/get_merchant_dashboard";
    var form = new FormData();
    axios
        .post(api_url, form)
      .then((response) => {
        context.commit("GET_DASHBOARD", response.data.data);
        context.commit("SHOW_LOADER", false);
      })
      .catch((error) => {
        context.commit("SHOW_LOADER", false);
        context.commit("SHOW_SNACKBAR", {
          success: false,
          message: "Something went wrong ,Please try again.",
        });
      });
  },
  editpackage(context, data) {
    context.commit("SHOW_LOADER", true);
    var api_url = "/api/admin/editpackage";
    const form = new FormData();
    form.append("package_id", data.package_id);
    form.append("package_title", data.package_title);
    form.append("package_desc", data.package_desc);
    form.append("amount", data.amount);
    form.append("validity", data.validity);
    form.append("api_hit_count", data.api_hit_count);
    axios
      .post(api_url, form)
      .then((response) => {
        context.commit("SHOW_LOADER", false);
        var success_statuses = [200, 201, 202, 203, 204, 205];
        if (success_statuses.includes(response.data.status)) {
         

          context.commit("PACKAGES_LIST", response.data.data);
          context.commit("SHOW_SNACKBAR", {
            success: true,
            message: response.data.message,
          });
          
        } else {
          context.commit("SHOW_SNACKBAR", {
            success: false,
            message: response.data.message,
          });
        }
      })

      .catch((error) => {
        context.commit("SHOW_LOADER", false);

        var errorMsg = "Something went wrong.";
        if (error.response.data.message) {
          errorMsg = error.response.data.message;
        }
        context.commit("SHOW_SNACKBAR", {
          success: false,
          message: errorMsg,
        });
      });
  },
  deletePackage(context, data) {
    context.commit("SHOW_LOADER", true);
    var id = data;
    var delete_url = "/api/admin/package/delete/";
    axios
      .get(delete_url + id)

      .then((response) => {
        context.commit("SHOW_LOADER", false);
        var success_statuses = [200, 201, 202, 203, 204, 205];
        if (success_statuses.includes(response.data.status)) {
          context.commit("PACKAGES_LIST", response.data.data);
          context.commit("SHOW_SNACKBAR", {
            success: true,
            message: response.data.message,
          });
        } else {
          context.commit("SHOW_SNACKBAR", {
            success: false,
            message: response.data.message,
          });
        }
      })
      .catch((error) => {
        context.commit("SHOW_LOADER", false);

        // //=====> delete api error ", data.id)
        var errorMessage = "Logic Exception";
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        context.commit("SHOW_SNACKBAR", {
          success: true,
          message: error.response.data.message,
        });
        
      });
  },
  create_package(context, data) {
    context.commit("SHOW_LOADER", true);
    var api_url = "/api/admin/create_package";
    const form = new FormData();
    form.append("package_title", data.package_title);
    form.append("package_desc", data.package_desc);
    form.append("amount", data.amount);
    form.append("validity", data.validity);
    form.append("api_hit_count", data.api_hit_count);
    axios
      .post(api_url, form)
      .then((response) => {
        context.commit("SHOW_LOADER", false);
        var success_statuses = [200, 201, 202, 203, 204, 205];
        if (success_statuses.includes(response.data.status)) {
         

          context.commit("PACKAGES_LIST", response.data.data);
          context.commit("SHOW_SNACKBAR", {
            success: true,
            message: response.data.message,
          });
        
        } else {
          context.commit("SHOW_SNACKBAR", {
            success: false,
            message: response.data.message,
          });
         
        }
      })

      .catch((error) => {
        var errorMsg = "Something went wrong.";
        if (error.response.data.message) {
          errorMsg = error.response.data.message;
        }
        context.commit("SHOW_SNACKBAR", {
          success: false,
          message: errorMsg,
        });
        context.commit("SHOW_LOADER", false);
      });
  },
  
  subscribe_pkg(context, data) { 
    context.commit("SHOW_LOADER", true);
    var api_url = "/api/merchant/subscribe_pkg";
    const form = new FormData();
    form.append("package_id", data.id);
    axios
      .post(api_url, form)
      .then((response) => {
        context.commit("SHOW_LOADER", false);
        if (response.data) {
        
          context.commit("SUBSCRIBED_PACKAGES_LIST", response.data.data);
          context.commit("SHOW_SNACKBAR", {
            success: true,
            message: response.data.message,
          });
        
        } else {
          context.commit("SHOW_SNACKBAR", {
            success: false,
            message: "This package has already subscribed.",
          });
          
        }
      })

      .catch((error) => {
        context.commit("SHOW_LOADER", false);
        var errorMsg = "Something went wrong.";
        if (error.response) {
          errorMsg = error.response.data.message;
        }
        context.commit("SHOW_SNACKBAR", {
          success: false,
          message: errorMsg,
        });
       
      });
  },
  checkPackage(context, data) {
    context.commit("SHOW_LOADER", true);
    var api_url = "/api/merchant/checkPackage";
    axios
      .get(api_url)
      .then((response) => {
          context.commit("SUBSCRIBED_PACKAGES_LIST", response.data.data);
          context.commit("SHOW_LOADER", false);
      
      })
      .catch((error) => {
        
        context.commit("SHOW_LOADER", false);
      });
  },
  getPackages(context) {
    context.commit("SHOW_LOADER", true);
    var api_url = "/api/merchant/getPackages";
    axios
      .get(api_url)
      .then((response) => {
        context.commit("PACKAGES_LIST", response.data.data);
        context.commit("SHOW_LOADER", false);
      })
      .catch((error) => {
        //(error);
        context.commit("SHOW_LOADER", false);
      });
  },
};
const mutations = {
  SHOW_LOADER(state, status) {
    state.loaderstate = status;
  },
  PACKAGES_LIST(state, packagesList) {
    state.packagesList = packagesList;
  },
  GET_DASHBOARD(state, dashboardDetails) {
    state.dashboardDetails = dashboardDetails;
  },
  SUBSCRIBED_PACKAGES_LIST(state, subPackagesList) {
    state.subPackagesList = subPackagesList;
  },
  //GET_DASHBOARD
};
export default {
  state,
  getters,
  actions,
  mutations,
};
