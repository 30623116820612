//

import { reject } from "lodash";
import Vue from "vue";
import axios from "axios";
const state = {
  download_path: "",
  profile: {},

  showSnackBar: {
    show: false,
    message: "",
    color: ""
},
};

const getters = {};

const actions = {
  downloadPlugin(context, data) {
    return new Promise((resolve , reject) => {
       
    const formdata = new FormData();
    formdata.append("email", data.email);
    formdata.append("GCtoken", data.GCtoken);
    
    axios
      .post("/api/downloadPlugin", formdata ,{
        responseType: 'blob'
       })
      .then((response) => {

        resolve(response);
        if(response.data != ""){         
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'plugin.zip');
          document.body.appendChild(fileLink);

          fileLink.click();
            // window.open(resp.data);
        }
        else{
          context.commit("SHOW_SNACKBAR", {
            color: "red",
            message: "Sorry File not Found !!"
        });

        }
        
      })
      .catch((err) => {
        reject(err)
        context.commit("SHOW_SNACKBAR", {
          color: "red",
          message: "Sorry File not Found !!"
      });
        //Vue.prototype.$printLog("==err===> downloadPlugin"+err);
      });
    });
    
  },
};

const mutations = {
  download_plugin_path(state, data) {
    state.download_path = data;
  },
  userSuccess: (state, resp) => {
    state.status = "success";
    Vue.set(state, "profile", resp);
  },
  userError: (state) => {
    state.status = "error";
  },
  SHOW_SNACKBAR(state, payload) {
    state.showSnackBar.show = true;
    state.showSnackBar.message = payload.message;
    state.showSnackBar.color = payload.color;
},
};

export default {
  state,
  getters,
  actions,
  mutations,
};
