import Cookies from "js-cookie";
import axios from "axios";

const state = {
    is_type: "",
    clearFormData: false,
    loaderstate: false,
    ticketsData: [],
    navbarData: [],
    assetsData:{},
    companyData: [],
    individualData: [],
    businessData: [],
    nav_url: {
        base_uri: '',
        state: ''
    },
    showSnackBar: {
        show: false,
        message: "",
        color: "",
    },
};

const getters = {};

const actions = {
    navUrl(context, data) {
        if (data.uri == 'home') {
            context.commit("NAV_URL", { uri: '', state: false });

        } else {
            var uri = window.location.origin;

            context.commit("NAV_URL", { base_uri: uri, state: true });
        }
    },
    async addNavbar(context, data) {
        context.commit("CLEAR_FORM_DATA", false);
        context.commit("SHOW_LOADER", true);
        var user_api = "/api/admin/add_Navbar";
        await axios
            .post(user_api, data)
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Created succesfully",
                });
                context.commit("CLEAR_FORM_DATA", true);
                context.commit("SHOW_LOADER", false);
                context.dispatch('getnavbarData');
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                var errorMsg = "Insert failed";

                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: errorMsg,
                });
            });
    },
    async addIndiv(context, data) {
        context.commit("CLEAR_FORM_DATA", false);
        context.commit("SHOW_LOADER", true);
        var user_api = "/api/admin/addIndividual";
        await axios
            .post(user_api, data)
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Created succesfully",
                });
                context.commit("CLEAR_FORM_DATA", true);
                context.commit("SHOW_LOADER", false);
                context.dispatch('getindividualData');
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                var errorMsg = "Insert failed";

                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: errorMsg,
                });
            });
    },
   async addCompany(context, data) {
        context.commit("CLEAR_FORM_DATA", false);
        context.commit("SHOW_LOADER", true);
        var user_api = "/api/admin/addCompany";
        await axios
            .post(user_api, data)
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Created succesfully",
                });
                context.commit("CLEAR_FORM_DATA", true);
                context.commit("SHOW_LOADER", false);
                context.dispatch("getcompanyData");

            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                var errorMsg = "Insert failed";

                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: errorMsg,
                });
            });
    },
    async addBusiness(context, data) {
        context.commit("CLEAR_FORM_DATA", false);
        context.commit("SHOW_LOADER", true);
        var user_api = "/api/admin/addBusiness";
        await axios
            .post(user_api, data)
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Created succesfully",
                });
                context.commit("SHOW_LOADER", false);
                context.commit("CLEAR_FORM_DATA", true);
                context.dispatch("getbusinessData");
                
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                var errorMsg = "Insert failed";

                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: errorMsg,
                });
            });
             
    },
    async editBusiness(context, data) {
        context.commit("CLEAR_FORM_DATA", false);
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/editBusiness";
       await axios
            .post(api_url, data)
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "updated successfully",
                });
                context.commit("CLEAR_FORM_DATA", true);
                context.commit("SHOW_LOADER", false);
                context.dispatch("getbusinessData");

            })
            .catch((error) => {
                context.commit("CLEAR_FORM_DATA", false);
                var error_msg = "failed data updated..";
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: error_msg,
                });
                context.commit("SHOW_LOADER", false);
            });
    },
    async editNavbar(context, data) {
        context.commit("CLEAR_FORM_DATA", false);
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/edit_navbar";
        await axios
            .post(api_url, data)
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "updated successfully",
                });
                context.commit("CLEAR_FORM_DATA", true);
                context.commit("SHOW_LOADER", false);
                context.dispatch('getnavbarData');
            })
            .catch((error) => {
                context.commit("CLEAR_FORM_DATA", false);
                var error_msg = "failed data updated..";
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: error_msg,
                });
                context.commit("SHOW_LOADER", false);
            });
    },
   async editCompany(context, data) {
        context.commit("CLEAR_FORM_DATA", false);
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/editCompany";
        await axios
            .post(api_url, data)
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "updated successfully",
                });
                context.commit("CLEAR_FORM_DATA", true);
                context.commit("SHOW_LOADER", false);
                context.dispatch("getcompanyData");
                
            })
            .catch((error) => {
                context.commit("CLEAR_FORM_DATA", false);
                var error_msg = "failed data updated..";
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: error_msg,
                });
                context.commit("SHOW_LOADER", false);
            });
    },
     deleteNavbar(context, data) {

        
        context.commit("SHOW_LOADER", true);
        // var api_url = "/api/deleteNavbar/" + data;
        var api_url = "/api/admin/deleteNavbar";

        var formdata = new FormData();
        formdata.append('id' , data);

        

         axios
            .post(api_url, formdata)
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Deleted successfully",
                });
                context.commit("SHOW_LOADER", false);

            })
            .catch((error) => {
                var error_msg = "Deletion failed";
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: error_msg,
                });
                context.commit("SHOW_LOADER", false);
            });
    },
     deleteComp(context, data) {
        context.commit("SHOW_LOADER", true);
        // var api_url = "/api/deleteComp/" + data;
        var api_url = "/api/admin/deleteComp";


        var formdata = new FormData();
        formdata.append('id' , data);

        

         axios
            .post(api_url, formdata)

       
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Deleted successfully",
                });
                context.commit("SHOW_LOADER", false);
                context.dispatch("getcompanyData");


            })
            .catch((error) => {
                var error_msg = "Deletion failed";
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: error_msg,
                });
                context.commit("SHOW_LOADER", false);
            });
    },
     deleteBusiness(context, data) {
        context.commit("SHOW_LOADER", true);
        // var api_url = "/api/delete_business_link/" + data;

        var api_url = "/api/admin/delete_business_link";
        var formdata = new FormData();
        formdata.append('id' , data);
         axios
            .post(api_url, formdata)
 
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Deleted successfully",
                });
                context.commit("SHOW_LOADER", false);
                context.dispatch("getbusinessData");

            })
            .catch((error) => {
                var error_msg = "Deletion failed ";
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: error_msg,
                });
                context.commit("SHOW_LOADER", false);
            });
    },
     deleteIndividual(context, data) {
        context.commit("SHOW_LOADER", true);
    //     var api_url = "/api/deleteIndividual/" + data;
    //  axios
    //         .get(api_url, data)

    var api_url = "/api/admin/deleteIndividual";
    var formdata = new FormData();
    formdata.append('id' , data);
     axios
        .post(api_url, formdata)

            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "Deleted successfully",
                });
                context.commit("SHOW_LOADER", false);
                context.dispatch('getindividualData');

            })
            .catch((error) => {
                var error_msg = "Deletion failed ";
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: error_msg,
                });
                context.commit("SHOW_LOADER", false);
            });
    },
    async editIndiv(context, data) {
        context.commit("CLEAR_FORM_DATA", false);
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/editIndiv";
        await axios
            .post(api_url, data)
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "updated successfully",
                });
                context.commit("CLEAR_FORM_DATA", true);
                context.commit("SHOW_LOADER", false);
                context.dispatch('getindividualData');

            })
            .catch((error) => {
                context.commit("CLEAR_FORM_DATA", false);
                var error_msg = "failed data updated..";
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: error_msg,
                });
                context.commit("SHOW_LOADER", false);
            });
    },
    async updateTicket(context, data) {
        // alert(data.id);
        context.commit("CLEAR_FORM_DATA", false);
        const form = new FormData();
        //Vue.prototype.$printLog("======>STORE FILE==>"+ data);
        form.append("title", data.title);
        form.append("launch", data.launch);
        form.append("orderNo", data.orderNo);
        form.append("page", data.page);
        form.append("image", data.image);
        form.append("shortimage", data.shortimage);
        form.append("top_image", data.top_image);
        form.append("show_topImage", data.show_topImage);
        form.append("desc", data.desc);
        form.append("short_desc", data.short_desc);
        form.append("id", data.id);
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/admin/ticket_update/" + data.id;
        await axios
            .post(api_url, form)
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: response.data.message,
                });
                context.commit("SHOW_LOADER", false);
                context.commit("CLEAR_FORM_DATA", true);
                context.dispatch("get_tickets");
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: "updation failed",
                });
            });
    },
     deleteItem(context, data) {
        context.commit("SHOW_LOADER", true);
        // var api_url = "/api/delete_ticket/" + data;
        //  axios
        //     .get(api_url)

        var api_url = "/api/admin/admin_delete_ticket";
        var formdata = new FormData();
        formdata.append('id' , data);
         axios
            .post(api_url, formdata)

            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: response.data.message,
                });
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: "deletion failed",
                });
            });
    },
    async add_new_ticket(context, data) {
        context.commit("CLEAR_FORM_DATA", false);
        context.commit("SHOW_LOADER", true);
        var user_api = "/api/admin/add_new_ticket";
        const form = new FormData();
        //Vue.prototype.$printLog("======>STORE FILE==>"+data);
        form.append("title", data.title);
        form.append("launch", data.launch);
        form.append("orderNo", data.orderNo);
        form.append("page", data.page);
        form.append("image", data.image);
        form.append("shortimage", data.shortimage);
        form.append("top_image", data.top_image);
        form.append("show_topImage", data.show_topImage);
        form.append("desc", data.desc);
        form.append("short_desc", data.short_desc);


        await axios
            .post(user_api, form)
            .then((response) => {
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: response.data.message,
                });
                context.commit("SHOW_LOADER", false);
                context.commit("CLEAR_FORM_DATA", true);
                context.dispatch("get_tickets");
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
                var errorMsg = "Insert failed";

                context.commit("SHOW_SNACKBAR", {
                    success: false,
                    message: errorMsg,
                });
            });
    },
    async getnavbarData(context, data) {
        context.commit("SHOW_LOADER", true);
        await axios
            .get("/api/navbar")
            .then((response) => {
                context.commit("GET_NAVBAR_DATA", response.data);

                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },
    async getindividualData(context, data) {
        context.commit("SHOW_LOADER", true);
        await axios
            .get("/api/individual")
            .then((response) => {
                context.commit("GET_INDIVIDUAL_DATA", response.data);

                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },
    async getbusinessData(context, data) {
        context.commit("SHOW_LOADER", true);
        await axios
            .get("/api/business")
            .then((response) => {
                context.commit("GET_BUSINESS_DATA", response.data);
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },
    async getcompanyData(context, data) {
        context.commit("SHOW_LOADER", true);
    await axios
            .get("/api/company")
            .then((response) => {
                context.commit("GET_COMPANY_DATA", response.data);
                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },
    async get_tickets(context, data) {
        context.commit("SHOW_LOADER", true);
       await axios
            .get("/api/get_tickets")
            .then((response) => {
                context.commit("GET_TICKETS_DATA", response.data);

                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },
    async get_assets(context, data) {
        context.commit("SHOW_LOADER", true);
        await axios
            .get("/api/get_assets")
            .then((response) => {
               
                context.commit("GET_ASSETS_DATA", response.data.data);

                context.commit("SHOW_LOADER", false);
            })
            .catch((error) => {
                context.commit("SHOW_LOADER", false);
            });
    },
};

const mutations = {
    GET_TICKETS_DATA(state, data) {

        var temp_data = data;
        state.ticketsData = [...temp_data];
    },

    GET_ASSETS_DATA(state, data) {
        var temp_data = data;
        state.assetsData = {...temp_data};
    },
    NAV_URL(state, data) {
        state.nav_url = data;
    },
    GET_BUSINESS_DATA(state, data) {
        var temp_data = data;
        state.businessData = [...temp_data];
    },
    GET_INDIVIDUAL_DATA(state, data) {
        var temp_data = data;
        state.individualData = [...temp_data];
    },

    GET_COMPANY_DATA(state, data) {
        var temp_data = data;
        state.companyData = [...temp_data];
    },
    GET_NAVBAR_DATA(state, data) {
        var temp_data = data;
        state.navbarData = [...temp_data];
    },

    SHOW_LOADER(state, status) {
        state.loaderstate = status;
    },

    SET_IS_TYPE(state, type) {
        state.is_type = type;
    },

     
    SHOW_SNACKBAR(state, payload) {

     
        state.showSnackBar.show = true;
        state.showSnackBar.message = payload.message;
        var color = "red";
        if (payload.success) {
            color = "primary";
        }
        state.showSnackBar.color = color;
    },
    CLEAR_FORM_DATA(state, status) {
        state.clearFormData = status;
    },
    

};

export default {
    state,
    getters,
    actions,
    mutations,
};