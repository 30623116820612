<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">Merchant <span>Registration</span></h4>
             <br />
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <img
            class="v3-logo"
            :src="'/assets/img/logo.png'"
            alt="Logo"
          />

          <p class="v3-sub-title">One app - all your connections</p>

          <h2>Hi, Welcome!</h2>

          <p class="v3-sub-p">Reset your password</p>

          <v-row justify="center">
            <v-col cols="12" sm="12" md="7">
              <v-text-field
                background-color="white"
                v-model="merchant.email"
                class="h50-field"
                placeholder="Enter your email"
                :error-messages="emailErrors"
                @input="$v.merchant.email.$touch()"
                @blur="$v.merchant.email.$touch()"
                required
                outlined
              ></v-text-field>

              <v-btn
                class="primary-bg-line-btn"
                :loading="loader"
                outlined
                large
                @click="forgotPassword()"
                block
                depressed
              >
                Send reset password link
              </v-btn>
            </v-col>
          </v-row>

          <div @click="goBack()" class="auth-back-btn i">
            <v-btn class="v3-div-clr" elevation="0" depressed flex>
              <img
                class="v3-back-btn"
                :src="'/assets/v3_assets/back_btn.svg'"
              />
              Back
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import MerchantBannerWidget from "../auth/MerchantBannerWidget.vue";
import axios from "axios";
export default {
  components: {
    MerchantBannerWidget,
  },

  computed: {
    ...mapState({}),
    emailErrors() {
      const errors = [];
      if (!this.$v.merchant.email.$dirty) return errors;
      !this.$v.merchant.email.required && errors.push("Email is required.");
      !this.$v.merchant.email.email && errors.push("Email must be valid.");
      return errors;
    },
  },
  data() {
    return {
      loader: false,
      show2: false,
      msg_info: "",
      merchant: {
        email: "",
      },
      snackbar: {
        show: false,
        message: "",
        color: null,
      },
    };
  },
  validations: {
    merchant: {
      email: {
        email,
        required,
      },
    },
  },
  methods: {
    async forgotPassword() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.snackbar = {
          message: "Please enter your Valid Email",
          color: "red",
          show: true,
        };
      } else {
        const form = new FormData();
        form.append("email_id", this.merchant.email);
        await this.$recaptchaLoaded();
        const GCtoken = await this.$recaptcha("merchantResetPassword");
        form.append("GCtoken", GCtoken);
        this.loader = true;
        axios
          .post("/api/v3/merchant_forgot_Password", form)
          .then((response) => {
            this.loader = false;
            this.$printLog("===password", response.data.message);
            // this.$router.push({ name: "merchantEmailLogin" });
            this.close();
          })
          .catch((error) => {
            this.loader = false;
            this.msg_info =
              "If an Account Exists , You will receive an email with link to reset your password";
            this.close();
            this.snackbar = {
              message:
                "If an Account Exists , You will receive an email with link to reset your password",
              color: "red",
              show: true,
            };
          });
      }
    },
    close() {
      this.dialog = false;
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
