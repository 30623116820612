import axios from "axios";
import { reject } from "lodash";

const state = {
  settingsData: {},
  settingsList :[],
  loaderstate: false,
  plugin_downloaded : [], // plugin downloader list
  // contactUsPageData: {
  //   address: "",
  //   email: "",
  //   service_number: "",
  //   blog_link: "",
  //   map_latitude: 0,
  //   map_longitude: 0,
  // },
  showSnackBar: {
    show: false,
    message: "",
    color: "",
  },
};

const getters = {};

const actions = {

  get_setting(context, data) {

    context.commit("SHOW_LOADER", true);
    var formData = new FormData();
    formData.append('is_login' , data? 1 : 0);
 
    var api_url = "/api/admin/get_setting";
    axios
      .post(api_url , formData)
      .then((response) => {
        context.commit("SHOW_LOADER", false);
       

        // response.data.data.forEach((seting) => {
        //   if (seting.key == "contact_address") {
        //     state.contactUsPageData.address = seting.value;
        //   } else if (seting.key == "contact_email") {
        //     state.contactUsPageData.email = seting.value;
        //   } else if (seting.key == "contact_number") {
        //     state.contactUsPageData.service_number = seting.value;
        //   } else if (seting.key == "map_latitude") {
        //     state.contactUsPageData.map_latitude = parseFloat(seting.value);
        //   } else if (seting.key == "map_longitude") {
        //     state.contactUsPageData.map_longitude = parseFloat(seting.value);
        //   } else if (seting.key == "blog_link") {
        //     state.contactUsPageData.blog_link = seting.value;
        //   } else {
        //   }
        // });

        context.commit("SETTINGS", response.data.data);
      })
      .catch((error) => {
        context.commit("SHOW_LOADER", false);
      });
  },



  get_settings_by_keys(context, keys) {

    context.commit("SHOW_LOADER", true);
    var formData = new FormData();
    formData.append('keys' , JSON.stringify(keys));
 
    var api_url = "/api/get_settings_by_keys";
    axios
      .post(api_url , formData)
      .then((response) => {
          
        if(response.data.data.map_latitude){
          response.data.data.map_latitude = parseFloat(response.data.data.map_latitude);
        }
        if(response.data.data.map_longitude){
          response.data.data.map_longitude = parseFloat(response.data.data.map_longitude);
        }

        context.commit("SETTINGS_OBJECT", response.data.data);     
        context.commit("SHOW_LOADER", false);
      })
      .catch((error) => {
        context.commit("SHOW_LOADER", false);
      });
  },

  update_setting(context, data) {
    context.commit("SHOW_LOADER", true);
    const formData= new FormData();
    // Vue.prototype.$printLog("===========>JSON DATA"+JSON.stringify(data));
    var val=JSON.stringify(data);

    formData.append("json",val);

    var api_url = "/api/admin/update_setting";
    axios
      .post(api_url, formData)
      .then((response) => {
        
          context.commit("SHOW_SNACKBAR", {
            success: true,
            message: "Saved succesfully",
          });
        context.commit("SHOW_LOADER", false);
      })
      .catch((error) => {
         
        context.commit("SHOW_LOADER", false);
        var errorMessage = "Logic Exception";
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        context.commit("SHOW_SNACKBAR", {
          success: false,
          message: errorMessage,
        });
      });
  },
  update_single_setting(context, data) {
    context.commit("SHOW_LOADER", true);
    const formData= new FormData();
    // Vue.prototype.$printLog("===========>JSON DATA"+JSON.stringify(data));

    var api_url = "/api/admin/update_single_setting";
    axios
      .post(api_url, data)
      .then((response) => {
        
          context.commit("SHOW_SNACKBAR", {
            success: true,
            message: "Saved succesfully",
          });
        context.commit("SHOW_LOADER", false);
      })
      .catch((error) => {
         
        context.commit("SHOW_LOADER", false);
        var errorMessage = "Logic Exception";
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        context.commit("SHOW_SNACKBAR", {
          success: false,
          message: errorMessage,
        });
      });
  },

  upload_plugin(context, file) {
    return new Promise((resolve , reject) => {

    context.commit("SHOW_LOADER", true);
    var api_url = "/api/admin/upload_plugin";
    var formDATA = new FormData();
    formDATA.append("file", file);

    axios
        .post(api_url, formDATA)
        .then(response => {
                resolve(response)
                context.commit("SHOW_SNACKBAR", {
                    success: true,
                    message: "File Uploaded Successfully",
                });
 
            context.commit("SHOW_LOADER", false);

        })
        .catch(error => {
            reject(error);
            context.commit("SHOW_SNACKBAR", {
                success: false,
                message: error.response ?  error.response.data.message : "Somthing went wrong try again.",
            });

            context.commit("SHOW_LOADER", false);
        });
      });
},


get_plugin_downloaded(context, data) {
  context.commit("SHOW_LOADER", true);
  var api_url = "/api/admin/get_plugin_downloaded";
  axios
    .post(api_url)
    .then((response) => {
     
      context.commit("SHOW_LOADER", false);
        context.commit("PLUGIN_DOWNLOADED", response.data.data);
       
    })
    .catch((error) => {
      context.commit("SHOW_LOADER", false);
    });
},


};

const mutations = {
  SHOW_LOADER(state, status) {
    state.loaderstate = status;
  },
  SHOW_SNACKBAR(state, payload) {
    state.showSnackBar.show = true;
    state.showSnackBar.message = payload.message;
    var color = "red";
    if (payload.success) {
      color = "primary";
    }
    state.showSnackBar.color = color;
  },

  SETTINGS(state, settings) {
    state.settingsList = settings;
  },

  SETTINGS_OBJECT(state, settings) {
    state.settingsData = settings;
  },

  
  PLUGIN_DOWNLOADED(state, plugin_downloaded) {
    state.plugin_downloaded = plugin_downloaded;
  },
  
};

export default {
  state,
  getters,
  actions,
  mutations,
};

