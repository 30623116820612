import Cookies from "js-cookie";
import axios from "axios";

const state = {};

const getters = {};

const actions = {

    accept: (context, commit) => {
        axios
            .post("/setCookies")
            .then((response) => {
                Vue.prototype.$printLog("========> ", response.data.data);
                Cookies.set("data", this.datalist, { expires: remember ? 365 : 1 });
            })
            .catch((err) => {});
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};