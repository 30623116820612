import Cookies from 'js-cookie'
import Vue from 'vue';
import Errors from './../../modules/errors';
import { router } from "../../router/routes";
import axios from "axios";
const state = {
    loginState: false,
    is_client: false,
    hasLoadedOnce: false,
    errors: new Errors()

}

const getters = {
    authErrors: state => state.errors,
}

const actions = {

    logViewer(context, data) {
        var api_url = "/api/logViewer2";
        context.commit("IS_CLIENT", false);
        //axios
        //    .get(api_url)
        //     .then(response => {
        // Vue.prototype.$printLog("=======logs===========response.data===>", response.data);
        // context.commit("INDUSTRY_SECTOR", response.data);
        //  var cookies_data=Cookies.get('log_viewer');
        //  if(!cookies_data){
        //     Cookies.set('log_viewer', 1, { expires: false ? 365 : 1 });
        //  }


        context.commit("IS_CLIENT", true);
        // 
        // })
        // .catch(error => {
        //     //(error);
        //     Vue.prototype.$printLog("==========logs========error response.data===>", error);
        //     context.commit("IS_CLIENT", false);

        // });
        // READ COMMENT HERE
        // // call in vue page 
        // this.$printLog("message");
        // // calll in js page
        // Vue.prototype.$printLog("message");
    },
    authRequest: ({ commit, dispatch }, payload) => {
        let actionUrl = '/login';
        let remember = payload.remember ? payload.remember : false;
        let data = {
            'email': payload.email,
            'password': payload.password,
            'GCtoken': payload.GCtoken,
            'device_detail': payload.device_info
        }
        if (payload.action == 'register') {
            actionUrl = '/register';
            data = {
                'name': payload.name,
                'email': payload.email,
                'password': payload.password,
                'password_confirmation': payload.password_confirmation
            }
        }
        if (payload.action == 'password-reset') {
            actionUrl = '/password/reset';
            data = {
                'token': payload.token,
                'email': payload.email,
                'password': payload.password,
                'password_confirmation': payload.password_confirmation
            }
        }
        return new Promise((resolve, reject) => {
            axios.post(actionUrl, data)
                .then((resp) => {
                    if (resp.data) {
                        Vue.prototype.$printLog("===============>ADMIN LOGIN::", JSON.stringify(resp));
                        let token = 'Bearer ' + resp.data.access_token;
                        axios.defaults.headers.common['Authorization'] = token;
                        var now = new Date();
                        now.setTime(now.getTime() + 1 * 3600 * 1000);
                        Cookies.set('access_token', token, { expires: remember ? now : 1 });
                        commit('authSuccess', token);
                        commit("ADMIN_LOGIN_STATUS", true);
                        dispatch('userRequest');
                        resolve(token);
                    } else {
                        commit("ADMIN_LOGIN_STATUS", false);
                        commit('authError', 'invalid_credentials');
                        reject(err);

                    }
                })
                .catch((err) => {
                    commit("ADMIN_LOGIN_STATUS", false);
                    commit('authError', 'invalid_credentials');
                    reject(err);
                })
        })
    },
    getAuthUser: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            axios.post('/api/admin/getAuthUser', payload)
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    window.location.href="/admin/login"
                    reject(err);
                });
        })
    },

    authLogout: ({ commit, dispatch }, user_type) => {

        user_type = user_type || 1; // admin

        // if (user_type == 1)
            commit("ADMIN_LOGIN_STATUS", false);
        // if (user_type == 2)
            commit("MERCHANT_LOGIN_STATUS", false);
        // if (user_type == 3)
            commit("USER_LOGIN_STATUS", false);

        if (Cookies.get('access_token')) {
            //api call
            return new Promise((resolve, reject) => {
                axios.post('/logout')
                    .then((resp) => {
                        resolve(resp);
                        Cookies.remove('access_token');
                    })
                    .catch((err) => {
                        commit('authError', err.response.data);
                        reject(err);
                    });
            })
        }

    }
}

const mutations = {
    IS_CLIENT(state, is_client) {
        state.is_client = is_client;
    },
    ADMIN_LOGIN_STATUS(state, loginState) {

        
        // alert('A' + loginState)
        state.loginState = loginState;
    },

    authSuccess: (state, access_token) => {
        state.status = 'success';
        state.access_token = access_token;
        state.hasLoadedOnce = true;
    },
    authError: (state, err) => {


        let errors = err.errors ? err.errors : {};
        if (err.error == "invalid_credentials") {
            errors.invalid_credentials = ['The user credentials were incorrect.'];
        }
        state.status = 'error';
        state.hasLoadedOnce = true;
        Cookies.remove('access_token');
        // state.errors.record(errors);
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}