import axios from "axios";
import { reject } from "lodash";
const state = {
  logs: [],
  logstabledata: [],
  fileDetail: {},
  logsbylevel: [],
  totalServerItems: 0
};
const getters = {};

const actions = {
  /**
   * @description Get DashBoard List
   * @example It will get all Dashboard Logs Details from Database 
   * @requires null
   * @param null
   * @default context
   * @return json
   */
  getdashboard(context, data) {
    context.dispatch("globalState/actionloaderState", true, { root: true });
    const url = "/api/admin/GetTotalDashboardLogs";
    axios
      .post(url, data)
      .then(response => {
        context.dispatch("globalState/actionloaderState", false, {
          root: true
        });
        context.commit("UPDATE_DASHBOARD", response.data.data);
      })
      .catch(error => {
        context.dispatch("globalState/actionloaderState", false, {
          root: true
        });
        context.dispatch(
          "globalState/snackBarState",
          {
            show: true,
            color: "red",
            message: error.response.data.message
          },
          { root: true }
        );
      });
  },

  /**
   * @description Get Logs By Date
   * @example It will get all logs by date 
   * @requires null
   * @param null
   * @default context
   * @return json
   */
  GetAllLogsFiles(context, data) {

    return new Promise((resolve, reject) => {
      context.dispatch("globalState/actionloaderState", true, { root: true });
      const url = "/api/admin/GetAllLogsFiles";
      axios
        .post(url, data)
        .then(response => {
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          context.commit("UPDATE_LOGS_TABLE_DATA", response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          context.dispatch(
            "globalState/snackBarState",
            {
              show: true,
              color: "red",
              message: error.response.data.message
            },
            { root: true }
          );
          reject(false);
        });
    });
  },

  /**
   * @description Delete Log File
   * @example  It will delete log file 
   * @requires filename
   * @param [string] filename
   * @default context
   * @return json
   */
  DeleteLogFile(context, data) {

    return new Promise((resolve, reject) => {
      context.dispatch("globalState/actionloaderState", true, { root: true });
      const url = "/api/admin/DeleteLogFile";
      axios
        .post(url, data)
        .then(response => {
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          context.commit("UPDATE_LOGS_TABLE_DATA", response.data.data);
          context.dispatch(
            "globalState/snackBarState",
            {
              show: true,
              color: "primary",
              message: response.data.message
            },
            { root: true }
          );
          resolve(true);
        })
        .catch(error => {
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          context.dispatch(
            "globalState/snackBarState",
            {
              show: true,
              color: "red",
              message: error.response.data.message
            },
            { root: true }
          );
          reject(false);
        });
    });
  },

  /**
   * @description Download Log File
   * @example  It will download log file 
   * @requires filename
   * @param [string] filename
   * @default context
   * @return json
   */
  DownloadFile(context, data) {
    var api_url = "/api/admin/DownloadFile?filename=" + data.filename;
    window.open(api_url, data);
  },

  /**
   * @description Get Log File Detail
   * @example  It WIll Get Log File Details For Specific file
   * @requires filename
   * @param [string] filename
   * @default context
   * @return json
   */
  GetLogFileDetail(context, data) {

    return new Promise((resolve, reject) => {
      context.dispatch("globalState/actionloaderState", true, { root: true });
      const url = "/api/admin/GetLogFileDetail";
      axios
        .post(url, data)
        .then(response => {
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          context.commit("UPDATE_FILE_DETAIL", response.data.data);
          context.dispatch(
            "globalState/snackBarState",
            {
              show: true,
              color: "primary",
              message: response.data.message
            },
            { root: true }
          );
          resolve(true);
        })
        .catch(error => {
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          context.dispatch(
            "globalState/snackBarState",
            {
              show: true,
              color: "red",
              message: error.response.data.message
            },
            { root: true }
          );
          reject(false);
        });
    });
  },



  /**
   * @description  Get Logs By Level
   * @example It will get logs file by level 
   * @requires filename,level,page,options
   * @param [string] filename
   * @param [string] level
   * @param [string] page
   * @param [string] options
   * @default context
   * @return json
   */
  GetLogsByLevel(context, data) {

    return new Promise((resolve, reject) => {
      context.dispatch("globalState/actionloaderState", true, { root: true });
      const url = "/api/admin/GetLogsByLevel";
      axios
        .post(url, data)
        .then(response => {
          var total = Number(response.data.data.total);
          context.commit("SET_TOTAL_SERVER_ITEM", total);
          var arrayOfObj = Object.entries(response.data.data.data).map(
            e => e[1]
          );
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          context.commit("UPDATE_LOGS_BY_LEVEL", arrayOfObj);
          // context.dispatch(
          //   "globalState/snackBarState",
          //   {
          //     show: true,
          //     color: "primary",
          //     message: response.data.message
          //   },
          //   { root: true }
          // );
          resolve(true);
        })
        .catch(error => {
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          context.dispatch(
            "globalState/snackBarState",
            {
              show: true,
              color: "red",
              message: error.response.data.message
            },
            { root: true }
          );
          context.commit("UPDATE_LOGS_BY_LEVEL", []);
          reject(false);
        });
    });
  },

  getLogSettings(context, data) {
    return new Promise((resolve, reject) => {

      context.dispatch("globalState/actionloaderState", true, { root: true });
      const url = "/api/admin/get_log_settings";
      axios
        .post(url, data)
        .then(response => {
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          resolve(response)
        })
        .catch(error => {
          reject(error)
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          context.dispatch(
            "globalState/snackBarState",
            {
              show: true,
              color: "red",
              message: error.response ? error.response.data.message : "Something went wrong!"
            },
            { root: true }
          );
        });
    });

  },

  updateLogSettings(context, data) {
    return new Promise((resolve, reject) => {

      context.dispatch("globalState/actionloaderState", true, { root: true });
      const url = "/api/admin/update_log_settings";
      axios
        .post(url, data)
        .then(response => {
          resolve(response)
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          context.dispatch(
            "globalState/snackBarState",
            {
              show: true,
              color: "primary",
              message: response.data.message
            },
            { root: true }
          );
        })
        .catch(error => {
          reject(response)
          context.dispatch("globalState/actionloaderState", false, {
            root: true
          });
          context.dispatch(
            "globalState/snackBarState",
            {
              show: true,
              color: "red",
              message: error.response ? error.response.data.message : "Something went wrong!"
            },
            { root: true }
          );
        });
    });
  },

};

const mutations = {
  UPDATE_DASHBOARD(state, logs) {
    state.logs = logs;
  },

  UPDATE_LOGS_TABLE_DATA(state, data) {
    state.logstabledata = data;
  },

  UPDATE_FILE_DETAIL(state, detail) {
    state.fileDetail = detail;
  },

  UPDATE_LOGS_BY_LEVEL(state, logsbylevel) {
    state.logsbylevel = logsbylevel;
  },

  SET_TOTAL_SERVER_ITEM(state, totalServerItems) { //For Pagination
    state.totalServerItems = totalServerItems;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
