<template>
  <v-app>
    <v-main class="v3-auth-wrapper">
      <v-row class="v3-auth-row">
        <v-col class="v3-banner" cols="12" sm="6" md="6">
          <h4 class="sb-onb-sub-text">Merchant <span>Registration</span></h4>
          <br />
          <MerchantBannerWidget />
        </v-col>

        <v-col class="v3-auth" cols="12" sm="6" md="6">
          <br />
          <br />
          <img class="v3-logo" :src="'/assets/img/logo.png'" alt="Logo" />
          <p class="v3-sub-title">One app - all your connections</p>
          <h2>Let's get <br />you started!</h2>

          <p class="v3-sub-p">We've sent an activation code to your email</p>

          <v-row class="zero-flex" justify="center">
            <v-col cols="12" sm="12" lg="8">
              <v-otp-input :length="6" v-model="email_verify_otp"></v-otp-input>

              <br />

              <v-row justify="center">
                <p class="v3-underline-text" v-if="!email_resendFlag">
                  Resend code again
                </p>
                <p class="ml-2" v-if="!email_resendFlag">
                  {{ email_timeLeft }}s
                </p>
                <v-btn
                  color="primary"
                  v-if="email_resendFlag"
                  small
                  text
                  @click="resendCode()"
                  >Resend OTP</v-btn
                >
              </v-row>
              <br />

              <v-btn
                class="primary-bg-line-btn"
                :loading="loader"
                @click="otpVerification()"
                depressed
                block
                large
                outlined
              >
                Verify
              </v-btn>
            </v-col>
          </v-row>

          <div class="v3-auth-sub-footer">
            <p class="text-3">
              Already have an account?
              <span @click="signIn">Sign In</span>
            </p>
          </div>

          <div @click="goBack()" class="auth-back-btn i">
            <v-btn class="v3-div-clr" elevation="0" depressed flex>
              <img
                class="v3-back-btn"
                :src="'/assets/v3_assets/back_btn.svg'"
              />
              Back
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import MerchantBannerWidget from "../auth/MerchantBannerWidget.vue";
import axios from "axios";
export default {
  components: {
    MerchantBannerWidget,
  },
  computed: {
    ...mapState({}),
  },

  data: () => ({
    //Email Verification keys
    email_otp_send: false,
    email_confirmStatus: false,
    email_verify_otp: "",
    email_verify: false,
    email_code_confirm_dialog: false,
    email_resendFlag: false,
    email_timeLeft: 30,
    signup_from_login_dialog: false,
    auto_fill_click: false,
    showAddField: false,
    unique_mobile: true,
    is_mail_loader: false,
    is_phone_loader: false,
    status_email: 1,
    terms: "",
    terms_conditions: false,
    dialog: false,
    show1: false,
    show2: false,
    loader: false,

    merchant: {
      email_id: "",
    },
    snackbar: {
      show: false,
      message: "",
      color: null,
    },
    termsDialog: false,
  }),

  created() {
    var params = this.$route.params;
    if (Object.keys(params).length == 0) {
      this.$router.replace("/v3/merchant/signup");
    }
    this.req_data = params;
    this.email_timeLeft = 30;
    this.time();
  },

  methods: {
    resendCode() {
      var obj = {
        email: this.req_data.email,
      };
      axios
        .post("/api/email_send_signup_otp_api", obj)
        .then((response) => {
          if (response.data.status == 200) {
            this.email_otp_send = true;
            this.email_resendFlag = false;
            this.email_timeLeft = 30;
            this.time();
            this.snackbar.show = true;
            this.snackbar.color = "primary";
            this.snackbar.message = "OTP send your Email";
            this.existing_account = response.data.existing_account;
          } else {
            this.email_otp_send = false;
            this.errorSnackBar(response.data.message);
          }
        })
        .catch((e) => {
          this.email_otp_send = false;
          this.errorSnackBar(e.response.data.message);
        });

      return;
    },

    time() {
      if (this.email_timeLeft > 0) {
        setTimeout(() => {
          this.email_timeLeft--;
          this.time(); // Recursive call to continue the countdown
        }, 1000); // Update every 1 second (1000 milliseconds)
      } else {
        // Time's up, allow resend
        this.email_resendFlag = true;
      }
    },
    goBack() {
      this.$router.replace("/v3/merchant/signup");
    },
    signIn() {
      this.$router.push({ name: "merchantEmailLogin" });
    },

    async otpVerification() {
      if (!this.email_verify_otp) {
        var msg = "Please enter Otp";
        this.errorSnackBar(msg);

        this.snackbar = {
          message: "Enter valid otp",
          color: "red",
          show: true,
        };
        return false;
      }

      // Verify otp with server side api
      const form = new FormData();
      form.append("email", this.req_data.email);
      form.append("otp", this.email_verify_otp);
      await this.$recaptchaLoaded();
      const GCtoken = await this.$recaptcha("merchantOtp");
      form.append("GCtoken", GCtoken);
      this.loader = true;

      axios
        .post("/api/v3/email_verify_Otp_api", form)
        .then((response) => {
          this.loader = false;
          if (response.data.status == 200) {
            // var email = this.req_data.email;

            this.$router.push({
              name: "merchantRegisterYourDetail",
              params: { userInfo: response.data.data },
            });

            localStorage.setItem("user_email", response.data.data.email_id);
            this.email_verify = true;
            this.email_resendFlag = false;
            this.email_confirmStatus = true;
            this.email_otp_send = false;
          } else {
            this.email_email_verify = false;
            this.errorSnackBar(response.data.message);
          }
        })
        .catch((e) => {
          this.loader = false;
          this.email_verify = false;
          this.snackbar = {
            message: "Enter valid otp",
            color: "red",
            show: true,
          };
        });
      return;
    },

    errorSnackBar(msg) {
      this.snackbar.show = true;
      this.snackbar.color = "error";
      this.snackbar.message = msg;
    },
  },
};
</script>
