import axios from "axios";

const state = {
    generaldata: {},
    bannerSection: {},
    registatus: false,
    loaderstate: false,
    status: false,
    showSnackBar: {
        show: false,
        message: "",
        color: ""
    },
};

const getters = {};

const actions = {
    getBannerSection(context, data) {
        context.commit("SHOW_LOADER", true);
        var api_url = "/api/bannerSection";
        axios
            .get(api_url)
            .then(response => {
                var success_statuses = [200, 201, 202, 203, 204, 205];
                if (success_statuses.includes(response.data.status)) {
                    //Vue.prototype.$printLog("==================BANNER_SECTION_DATA.  ===>"+response.data.data);
                    context.commit("BANNER_SECTION_DATA", response.data.data);
                } else {}
                context.commit("SHOW_LOADER", false);

            })
            .catch(error => {
                context.commit("SHOW_LOADER", false);
            });

    },

}

const mutations = {

    //
    BANNER_SECTION_DATA(state, data) {

        state.bannerSection = data;
    },

    SHOW_LOADER(state, status) {
        state.loaderstate = status;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};